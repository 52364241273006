import {
  ref, onMounted, computed,
} from 'vue';
import { searchShipperList } from './api';

export default function useShipperOptions() {
  const shippers = ref([]);
  const filterKeyword = ref('');

  const setShipperOptions = async () => {
    const shipperList = await searchShipperList({});
    shippers.value = shipperList.map((item) => ({ ...item, shipperId: item.id }));
  };

  const shipperOptions = computed(() => shippers.value.filter(({
    shipperName,
    customerCode,
  }) => shipperName.includes(filterKeyword.value) || customerCode.includes(filterKeyword.value)));

  const setFilterKeyword = (keyword) => {
    filterKeyword.value = keyword;
  };

  onMounted(() => {
    setShipperOptions();
  });

  return {
    shipperOptions,
    setFilterKeyword,
  };
}
