import MainContent from '@/layout/MainContent.vue';
import KeepAliveLayout from '@/layout/KeepAliveLayout.vue';

import InLibrary from '@/constant/pageNames/inLibrary';

export default {
  path: '/in-library-management',
  component: MainContent,
  meta: { title: '在库管理', icon: 'icon-zaiku' },
  children: [{
    path: 'inventory-management',
    meta: { title: '库存管理', icon: 'table' },
  },
  {
    path: 'inventory-management/goods-inventory-query',
    meta: {
      title: '货品库存查询',
      trackNameZh: '在库管理/库存管理/货品库存查询/列表页',
    },
    component: () => import('@/views/in-library-management/inventory-management/goods-inventory-query/index.vue'),
  },
  {
    path: 'inventory-management/location-batch-query',
    meta: {
      title: '库位批次库存查询',
      trackNameZh: '在库管理/库存管理/库位批次库存查询/列表页',
    },
    component: () => import('@/views/in-library-management/inventory-management/location-batch-query/index.vue'),
  },
  {
    path: 'inventory-management/container-inventory-query',
    meta: {
      title: '容器库存查询',
      trackNameZh: '在库管理/库存管理/容器库存查询/列表页',
    },
    component: () => import('@/views/in-library-management/inventory-management/container-inventory-query/index.vue'),
  },
  {
    path: 'inventory-management/transaction-flow',
    component: KeepAliveLayout,
    children: [
      {
        path: '',
        meta: {
          title: '交易流水',
          catchName: InLibrary.TRANSACTION_FLOW,
          trackNameZh: '在库管理/库存管理/交易流水/列表页',
        },
        component: () => import('@/views/in-library-management/inventory-management/transaction-flow/index.vue'),
      },
    ],
  },
  {
    path: 'inventory-management/history-inventory-query',
    meta: {
      title: '历史库存查询',
      trackNameZh: '在库管理/库存管理/历史库存查询/列表页',
    },
    component: () => import('@/views/in-library-management/inventory-management/history-inventory-query/index.vue'),
  },
  {
    path: 'inventory-adjustment',
    component: KeepAliveLayout,
    children: [
      {
        path: '',
        meta: {
          title: '库存调整',
          catchName: InLibrary.INVENTORY_ADJUSTMENT,
          trackNameZh: '在库管理/库存调整/列表页',
        },
        component: () => import('@/views/in-library-management/inventory-adjustment/index.vue'),
      },
    ],
  },
  {
    path: 'library-transfer-management',
    component: KeepAliveLayout,
    children: [
      {
        path: '',
        meta: {
          title: '移库管理',
          catchName: InLibrary.LIBRARY_TRANSFER_MANAGEMENT,
          trackNameZh: '在库管理/移库管理/列表页',
        },
        component: () => import('@/views/in-library-management/library-transfer-management/index.vue'),
      },
    ],
  },
  {
    path: 'count-management',
    component: KeepAliveLayout,
    children: [
      {
        path: '',
        meta: {
          title: '盘点管理',
          catchName: InLibrary.COUNT_MANAGEMENT,
          trackNameZh: '在库管理/盘点管理/列表页',
        },
        component: () => import('@/views/in-library-management/count-management/index.vue'),
      },
    ],
  },
  {
    path: 'inventory-freeze-record',
    meta: {
      title: '库存冻结记录',
      trackNameZh: '在库管理/库存冻结记录/列表页',
    },
    component: () => import('@/views/in-library-management/inventory-freeze-record/index.vue'),
  },
  ],
};
