import { localStorageSet, localStorageGet, localStorageRemove } from './localStorage';

const TokenKey = 'TokenKey';
const longTokenKey = 'Token'; // 长token

export function getToken() {
  return localStorageGet(TokenKey);
}

export function setToken(token) {
  return localStorageSet(TokenKey, token ? `Bearer ${token}` : '');
}

export function removeToken() {
  return localStorageRemove(TokenKey);
}

export function getLongToken() {
  return localStorageGet(longTokenKey);
}

export function setLongToken(token) {
  return localStorageSet(longTokenKey, token || '');
}

export function removeLongToken() {
  return localStorageRemove(longTokenKey);
}
