import creatHttp from './creatHttp';
import env from '../../../env/index';
import download from './downloadHandler';

const otmsHttp = creatHttp(env.OTMS_WEB_PLUS_SERVER);
const bfsSsoHttp = creatHttp(env.BFS_SSO_SERVER);
const bfsUserWebHttp = creatHttp(env.BFS_USER_WEB_SERVER);
const warehouseHttp = creatHttp(env.WAREHOUSE_WEB_SERVER);
const bigDataHttp = creatHttp(env.BIG_DATA_SERVER);
// 老服务使用此 Http 服务
const noPrefixHttp = creatHttp('/');

export const wmsPlusHttp = creatHttp(env.WMS_WEB_PLUS);
wmsPlusHttp.download = download;
// return wmsPlusHttp.post('/warehouse_foundation/storehouse/export/list', rdata, {
//   // params,
//   responseType: 'blob',
//   // onreadystatechange(res) {
//   onreadystatechange(res) {
//     const { config, request } = res;
//     const { params = {}, downloadFileNameCustomed = 'export' } = config;
//     const name = params.fileName || downloadFileNameCustomed;
//     if (request.status === 200 && request.readyState === 4) {
//       const filename = `${name}.xlsx`;
//       const blob = new Blob([{
//         ...request.response,
//         type: 'application/json',
//       }], {
//         type:
//           'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
//       });
//       const csvUrl = URL.createObjectURL(blob);
//       const link = document.createElement('a');
//       link.href = csvUrl;
//       link.download = filename;
//       link.click();
//     }
//   },
// });

export {
  otmsHttp,
  bfsSsoHttp,
  bfsUserWebHttp,
  noPrefixHttp,
  bigDataHttp,
  warehouseHttp,
};
