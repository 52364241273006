import { positionOptions, fontSizeOptions } from '../../config';
import { DROP_TAG_PREFIX, CENTER } from '../../constants';

export const defaultPositionOptions = { ...positionOptions };
export const defaultFontSizeOptions = fontSizeOptions;

const TABLE = 'table';

export const defaultData = {};

export const defaultColumns = [];

export const defaultStyle = {
  textAlign: CENTER,
  minHeight: '26px',
  width: '100%',
  fontSize: fontSizeOptions[2],
};

export default {
  name: TABLE,
  drag: {
    title: '表格',
    suffixIcon: 'table4',
  },
  drop: {
    tagName: `${DROP_TAG_PREFIX}${TABLE}`,
  },
  editor: {
    text: '文字',
    align: '位置',
  },
  configuration: {
    data: { ...defaultData },
    style: { ...defaultStyle },
    columns: [...defaultColumns],
  },
};
