<template>
  <div ref="detailPage" class="detail-page-component">
    <div class="detail-page-component-content">
      <slot />
    </div>
    <footer>
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'DetailPage',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';

.detail-page-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .detail-page-component-content {
    flex: 1;
    overflow: auto;
  }
}
</style>
