import MainContent from '@/layout/MainContent.vue';

export default {
  path: '/warehouse-data',
  component: MainContent,
  meta: { title: '可视化仓库管理', icon: 'icon-renwu' },
  children: [
    {
      path: 'warehouse',
      meta: {
        title: '仓库',
        trackNameZh: '报表中心/可视化库存/页面',
      },
      component: () => import('@/views/3d-warehouse/warehouse/index.vue'),
    },
  ],
};
