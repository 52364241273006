<template>
  <el-form ref="formDom" v-bind="{ ...$attrs, ...$props }">
    <el-row>
      <slot />
    </el-row>
  </el-form>
</template>

<script>

import { ref } from 'vue';

export default {
  props: {
    labelWidth: {
      type: String,
      default: '150px',
    },
  },
  setup() {
    const formDom = ref({});
    async function clearValidate(val) {
      await formDom.value.clearValidate(val);
    }
    async function resetFields() {
      await formDom.value.resetFields();
    }
    async function validate() {
      await formDom.value.validate();
    }
    async function validateField(val) {
      await formDom.value.validateField(val);
    }
    return {
      formDom,
      clearValidate,
      resetFields,
      validate,
      validateField,
    };
  },
};
</script>
