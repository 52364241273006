import { getLongToken, removeLongToken } from '@/utils/auth';

// 白名单，不需要登录验证
const WarehousePath = '/warehouse';

export default function handlePermission(to) {
  // 这里处理2.5D仓库的特殊场景
  const { tag } = to.query;
  // 如果URL包含了tag与token 则判断为外来iframe. 则使得仓库不受路由校验影响
  const isIframeWareHousePath = tag && (to.path === WarehousePath);
  if (!isIframeWareHousePath) {
    const longToken = getLongToken();
    if (longToken) {
      removeLongToken();
    }
  }
  return to.path === '/login' ? { path: '/' } : true;
}
