<!--
 * @Author: your name
 * @Date: 2021-07-27 13:13:45
 * @LastEditTime: 2021-08-13 15:34:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fe-wms-v2-admin\src\thales\ExpandTable\ExpandTable.vue
-->
<template>
  <div class="collapse-component">
    <div
      v-for="(list, listIndex) in headerData"
      :key="listIndex"
      class="collapse-list"
    >
      <div class="collapse-header">
        <div class="before">
          <div
            v-for="(item, itemIndex) in header"
            :key="itemIndex"
            :style="{flexBasis: item.width}"
          >
            <nh-button
              v-if="item.isButton"
              type="text"
              @click="handleItemClick(listIndex, list)"
            >
              {{ `${listIndex === active? '收起': '展开'}` }}
              <i :class="[listIndex === active ? 'el-icon-caret-top': 'el-icon-caret-right']" />
            </nh-button>
            <div v-else>
              <label class="item-color"><span>{{ item.label }}</span>{{ list[item.prop] }}</label>
            </div>
          </div>
        </div>
        <div v-if="$slots.after">
          <slot name="after" :row="list" />
        </div>
      </div>
      <div v-if="listIndex=== active" class="collapse-body">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    accordion: Boolean,
    header: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    childData: {
      type: Function,
      default: () => () => {},
    },
  },
  data() {
    return {
      active: null,
      headerData: [],
    };
  },
  watch: {
    data: {
      handler(val) {
        this.headerData = val;
      },
      deep: true,
    },
  },
  methods: {
    handleItemClick(index, row) {
      if (this.active === index) {
        this.active = null;
      } else {
        this.active = index;
        this.childData(row);
      }
    },
  },

};
</script>
<style scoped lang="scss">
.collapse-component {
  .collapse-list {
    border-bottom: 1px solid #f0f0f0;

    & > :hover {
      background-color: #f2fbf8;
    }
  }

  .collapse-header {
    display: flex;
    font-size: 14px;
    line-height: 50px;
    justify-content: space-between;

    .before {
      display: flex;
      flex: 1;

      & > div {
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 8px;

        .item-color {
          color: #171717;

          & > span {
            color: #606266;
          }
        }
      }
    }
  }
}
</style>
