<template>
  <el-form
    label-width="100px"
    :model="config"
  >
    <!-- <el-form-item label="文字">
      <el-input v-model="config.data.text" @input="handleTextChange"></el-input>
    </el-form-item> -->
    <el-form-item label="字体大小">
      <el-select v-model="config.style.fontSize">
        <el-option
          v-for="size in defaultFontSizeOptions"
          :key="size"
          :label="size"
          :value="size"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="文字位置">
      <el-select v-model="config.style.textAlign">
        <el-option
          v-for="item in defaultPositionOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <!-- <el-form-item label="宽">
      <el-input v-model="config.style.width" @input="handleWidthChange"></el-input>
    </el-form-item>
    <el-form-item label="高">
      <el-input v-model="config.style.height" @input="handleHeightChange"></el-input>
    </el-form-item> -->
  </el-form>
  <el-row>
    <el-button
      type="primary"
      plain
      @click="handleColumnAddedDialogClick"
    >
      新增
    </el-button>
  </el-row>
  <el-table ref="columnsTable" :data="config.columns">
    <el-table-column prop="label" label="名称" />
    <el-table-column prop="prop" label="key值" />
    <el-table-column prop="width" label="宽度" />
    <el-table-column label="操作" width="90px">
      <template #default="scope">
        <el-button
          type="text"
          size="small"
          @click="handleRemoveColumn(scope.$index)"
        >
          删除
        </el-button>
        <el-button
          type="text"
          size="small"
          @click="handleEditColumn(scope.$index)"
        >
          编辑
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <AddColumn
    v-model="columnAddedDialog.show"
    :column="columnAddedDialog.column"
    @save="handleColumnSave"
  />
</template>

<script>
import {
  defineComponent, reactive, ref,
} from 'vue';
import {
  ElTable, ElTableColumn, ElButton, ElRow, ElForm, ElFormItem, ElSelect, ElOption,
} from 'element-plus';
import { defaultPositionOptions, defaultFontSizeOptions } from './config';

import AddColumn from './components/AddColumn.vue';

import useEditorTable from './useEditorTable';

// 没有选择编辑column时的index
const UNSELECT_INDEX = -1;

export default defineComponent({
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    ElRow,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    AddColumn,
  },
  props: {
    modelValue: {
      type: Object,
      defualt: () => ({
        columns: [],
        style: {},
      }),
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const columnsTable = ref(null);
    const columnAddedDialog = reactive({
      show: false,
      columnIndex: null,
    });

    let columnEditedIndex = UNSELECT_INDEX;

    const { config, removeColumn, updateColumns } = useEditorTable(props);

    // const updateConfig = (config) => {
    //   emit('update:modelValue', config);
    // };

    const openColumnAddedDialog = () => {
      columnAddedDialog.show = true;
    };

    const setColumnEditInfo = (columnIndex) => {
      columnEditedIndex = columnIndex;

      const column = config.value.columns[columnEditedIndex];

      columnAddedDialog.column = column || { ...column };
    };

    const closeColumnAddedDialog = () => {
      columnAddedDialog.show = false;
      setColumnEditInfo(UNSELECT_INDEX);
    };

    // methods

    const handleColumnAddedDialogClick = () => {
      openColumnAddedDialog();
    };

    const handleColumnSave = (column) => {
      updateColumns(column, columnEditedIndex);
      closeColumnAddedDialog();
    };

    const handleRemoveColumn = (index) => {
      removeColumn(index);
    };

    const handleEditColumn = (columnIndex) => {
      setColumnEditInfo(columnIndex);
      openColumnAddedDialog();
    };

    return {
      config,
      columnsTable,
      columnAddedDialog,
      defaultFontSizeOptions,
      defaultPositionOptions,
      handleColumnAddedDialogClick,
      handleRemoveColumn,
      handleColumnSave,
      handleEditColumn,
    };
  },
});
</script>
