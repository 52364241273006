<template>
  <el-table
    ref="tableComponent"
    class="table-component"
    v-bind="$attrs"
  >
    <template v-for="(index, name) in $slots" #[name]>
      <el-table-column
        v-if="name === 'default'"
        :key="index"
        align="center"
        type="selection"
        width="50"
      />
      <el-table-column
        v-if="name === 'default'"
        :key="index"
        type="index"
        label="序号"
        width="50"
      />
      <slot :name="name" />
    </template>
  </el-table>
</template>

<script>

export default {
  props: {
    title: {
      require: true,
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';

.table-component {
  th {
    background-color: #f6f8fa;
  }

  thead {
    font-weight: 500;
    color: $title_text_color;

    td,
    th {
      padding: 6px 0;
    }
  }

  tbody {
    td {
      padding: 0;
      height: 44px;
    }
  }

  tr {
    &.hover-row td {
      background-color: #f2fbf8;
    }
  }
}
</style>
