<template>
  <div ref="qrcode" />
</template>

<script>
import QRCode from 'qrcodejs2';

export default {
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      qrcode: null,
    };
  },
  computed: {
    $text() {
      return this.text || '';
    },
    $size() {
      return this.size || 200;
    },
  },
  created() {
    this.$nextTick(() => {
      this.handleQrcode();
    });
  },
  methods: {
    handleQrcode() { // 这里是调用的方法
      this.$refs.qrcode.innerHTML = '';
      this.qrcode = new QRCode(this.$refs.qrcode, {
        width: this.$size, // 设置宽度
        height: this.$size, // 设置高度
        text: this.$text,
      });
    },
  },
};
</script>
