<template>
  <div class="chan-drag-drop-layout">
    <div>
      <drag-item
        v-for="item in dragItems"
        :key="item.name"
        v-bind="item.drag"
        @dragstart="handleDragStart($event, item)"
      />
    </div>
    <div
      class="chan-drag-drop-layout__drop-view"
      @dragover="handleDragover"
      @drop="handleDrop"
    >
      <component
        :is="item.tagName"
        v-for="item in dropItems"
        :key="item.key"
        v-bind="configuration[item.key]"
        :class="{
          'chan-drag-drop-layout__drop-view__item': true,
          selected: item.key === selectedDropItem.key
        }"
        @click="handleDropItemClick(item)"
      />
    </div>
    <div class="chan-drag-drop-layout__editor">
      <ChanEditor
        v-if="showEditor"
        v-model="configuration[selectedDropItem.key]"
        :type="selectedDropItem.dragName"
      />
    </div>
  </div>
</template>
<script>
import 'element-plus/dist/index.css';

import {
  defineComponent, nextTick, reactive, ref,
} from 'vue';

import useDragDropLayout from './uses/use-drag-drop-layout';

import DragItem from './drag-item.vue';
import ChanDropText from './components/text/drop.vue';
import ChanDropTable from './components/table/drop.vue';
import DropGoodsBill from './components/goodsBill/drop.vue';
import ChanEditor from './components/editor-panel/index.vue';

export default defineComponent({
  name: 'DragDropLayout',
  components: {
    ChanEditor,
    DragItem,
    ChanDropText,
    ChanDropTable,
    DropGoodsBill,
  },
  props: {
    dragItemsOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const {
      filterDragItems,
      setDragItemName,
      allowDrop,
      getDropItemByDragItemName,
    } = useDragDropLayout();

    const showEditor = ref(true);
    const dragItems = filterDragItems(props.dragItemsOptions);
    const dropItems = reactive([]);
    const configuration = reactive({});
    const selectedDropItem = reactive({});

    // 添加dropItem
    const addDropItem = (dropItem) => {
      dropItems.push(dropItem);
    };

    // 添加configItem
    const addConfigItem = (config) => {
      configuration[config.key] = config;
    };

    const handleDropItemClick = ({ key, dragName }) => {
      if (key === selectedDropItem.key) {
        // 如果当前点击的元素已经被选中，不处理事件
        return;
      }

      showEditor.value = false;
      selectedDropItem.key = key;
      selectedDropItem.dragName = dragName;
      nextTick(() => {
        showEditor.value = true;
      });
    };

    // 开始拖拽
    const handleDragStart = (event, item) => {
      setDragItemName(event, item);
    };

    // 组件拖拽至视图上方
    const handleDragover = (event) => {
      // 允许放置
      allowDrop(event);
    };

    // 组件拖拽至视图上方
    const handleDrop = (event) => {
      const dropItem = getDropItemByDragItemName(event);

      addDropItem(dropItem.drop);
      addConfigItem(dropItem.configuration);
    };

    return {
      showEditor,
      dragItems,
      dropItems,
      configuration,
      selectedDropItem,
      handleDragStart,
      handleDragover,
      handleDrop,
      handleDropItemClick,
    };
  },
});
</script>
<style lang="scss">
.chan-drag-drop-layout {
  display: flex;
  &>div {
    border: 2px solid rgb(245, 225, 225);
  }

  .chan-drag-drop-layout__drop-view {
    flex: 1;

    border: 1px solid rgba(255, 0, 0, 0.2);
    &__item {
        cursor: pointer;
        border: 1px dashed rgba(255, 0, 0, 0.2);
    }
    .selected {
        background-color: rgba(255, 0, 0, 0.05)
    }
  }

  .chan-drag-drop-layout__editor {
    width: 300px;
    overflow: auto;
  }
}
</style>
