import { createStore } from 'vuex';
import user from './modules/user/index';
import menu from './modules/menu/index';
import app from './modules/app/index';

const store = createStore({
  modules: {
    user,
    menu,
    app,
  },
});

export default store;
