<template>
  <el-autocomplete
    v-bind="$attrs"
    :fetch-suggestions="querySearchAsync"
    placeholder="请选择或输入社会信用代码"
    value-key="label"
  />
</template>

<script>
import creditCode from './api';

async function getCreditCode(queryString) {
  let option = [];
  const res = await creditCode({
    corpCreditCodeLike: queryString || '',
  }) || [];
  option = res.map((list) => ({
    ...list,
    value: list.creditCode,
    label: `${list.corpName}(${list.creditCode})`,
  }));
  return option;
}

export default {
  setup() {
    const querySearchAsync = async (queryString, cb) => {
      const options = queryString ? await getCreditCode(queryString) : [];
      cb(options);
    };
    return {
      querySearchAsync,
    };
  },
};
</script>
