<template>
  <div>
    <template v-if="parentName === 'el-select'">
      <el-option
        v-for="option in options"
        :key="option.value"
        v-bind="option"
        :label="option.label"
        :value="option.value"
      />
    </template>

    <template v-if="parentName === 'el-checkbox-group'">
      <el-checkbox
        v-for="option in options"
        :key="option.value"
        v-bind="option"
        :label="option.value"
      >
        {{ option.label }}
      </el-checkbox>
    </template>

    <template v-if="parentName === 'el-radio-group'">
      <el-radio
        v-for="option in options"
        :key="option.value"
        v-bind="option"
        :label="option.value"
      >
        {{ option.label }}
      </el-radio>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FieldSub',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    parentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style>
.el-select,
.el-cascader,
.el-date-editor.el-input,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner,
.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}

.el-date-editor .el-range-separator {
  width: auto;
}
</style>
