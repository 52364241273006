import { computed } from 'vue';

export default function useShipperOptions(props) {
  // 货主逻辑：
  // 当传入v-model:shipper时，使用shipper对象
  // 当只传入v-model:shipperId时，使用v-model:shipperId组装shipper对象
  const selectedShipper = computed(() => {
    if (props.shipper?.shipperId) {
      return props.shipper;
    }

    return {
      shipperId: props.shipperId,
    };
  });

  return {
    selectedShipper,
  };
}
