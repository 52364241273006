<!--
 * @Author: 郑君婵
 * @Date: 2020-09-16 14:15:30
 * @LastEditTime: 2020-11-18 14:46:53
 * @Description: 一维码组件
 * @FilePath: /src/components/ChanBarCode/ChanBarCode.vue
-->
<template>
  <barcode
    v-bind="$attrs"
    :options="$barOptions"
  >
    {{ failText }}
  </barcode>
</template>
<script>
import VueBarcode from '@chenfengyuan/vue-barcode';

export default {
  name: 'ChanBarcode',
  components: {
    barcode: VueBarcode,
  },
  props: {
    failText: {
      type: String,
      default: '条形码创建失败',
    },
    size: {
      type: String,
      default: 'small',
    },
    width: {
      type: Number,
      default: 1,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  computed: {
    $barOptions() {
      return {
        margin: 0,
        width: this.width,
        height: this.height || (this.size === 'small' ? 25 : 35),
        fontSize: '12',
        textMargin: '-2',
      };
    },
  },
};
</script>
