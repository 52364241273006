<template>
  <div class="table__header--root">
    <div class="table__header--left">
      <slot name="headerLeft" />
    </div>
    <div class="table__header--right">
      <slot name="headerRight" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProTableHeader',
  setup() {
    return {};
  },
};
</script>

<style scoped>
.table__header--root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
</style>
