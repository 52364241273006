import { loginByPassword, getUserInfo } from '@/views/login/api';
import { unifyLogin } from '@/api/auth';
import { localStorageSet } from '@/utils/localStorage';
import { RECEIPT_AND_GOODS } from '@/constant/storage';

const actions = {
  // 登录
  async login({ commit, dispatch }, params) {
    const accountInfo = await loginByPassword(params);
    commit('setLoginParams', params);
    commit('setAccountInfo', accountInfo);
    dispatch('updateUserInfo');
  },

  // 切换租户
  async changeWarehouse({ state, commit }, warehouseId) {
    const params = {
      ...state.loginParams,
      warehouseId,
    };
    const accountInfo = await loginByPassword(params);
    commit('setAccountInfo', accountInfo);
  },
  async changeUnifyWarehouse({ state, commit }, warehouseId) {
    const params = {
      clientCode: 'wms_web',
      loginType: 'USER_ID',
      identityType: 'TENANT',
      identifier: state.accountInfo.userId,
      credential: state.accountInfo.refreshToken,
      loginAuth: 'TOKEN',
      loginExt: {
        warehouseLogin: true,
        warehouseId,
      },
    };
    const accountInfo = await unifyLogin(params);
    commit('setAccountInfo', accountInfo);
  },
  async logout({ commit }) {
    localStorageSet(RECEIPT_AND_GOODS, {});
    commit('setAccountInfo');
    commit('setUserInfo');
    window.location.href = '/#/base/owner-about/owner';
  },

  // 设置用户信息
  async updateUserInfo({ commit }) {
    const userInfo = await getUserInfo();
    commit('setUserInfo', userInfo);
  },
};

export default actions;
