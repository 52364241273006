<!--
 * 参数errMsgList: 错误列表
 * 参数isUpload: 是否上传成功
 * 参数tableColumn 表格信息绑定内容
 * 参数dataAmount: 上传的数据数量(共多少条数据)
 * 参数quantityLimit: 上传数量限制
 * @submitSuccess: 点击确定的方法
 * @cancel: 点击取消的方法
 * @upload: 上传的方法（如要验证则为验证方法，确定时再保存）
 * @downLoadTemplate: 下载模板文件方法
 * @clearAll: 全部清空方法
 * 插槽#titleBtnLeft（上传及模板下载按钮）
 * 插槽#titleBtnRight（右侧清空及文字说明按钮）
 * 插槽#contentTable（错误表格及分页数据）
 * 插槽#contentNoData（无数据状态）
 * 插槽#contentSuccess（上传成功状态）
 * 插槽#describe (描述)
-->
<template>
  <div class="dialog-import-box">
    <nh-dialog
      v-model="visible"
      v-bind="$attrs"
      title="导入"
      width="50%"
      custom-class="dialog-import"
      @close="clearAll"
    >
      <template #title>
        <div class="title-box">
          <slot name="title">
            <span class="title-name">导入</span>
            <span class="tips-box">温馨提示:单次最多只能导入{{ quantityLimit }}条数据</span>
          </slot>
        </div>
      </template>
      <slot name="bodyTitle" />
      <div class="import-btn-box">
        <div class="import-btn-left">
          <slot name="titleBtnLeft">
            <el-upload
              ref="upload"
              :limit="1"
              :show-file-list="false"
              name="file"
              action=""
              multiple
              :http-request="handleUploadHttpRequest"
              :on-success="handleUploadSuccess"
            >
              <nh-button
                type="primary"
                :loading="loading.handleUploadHttpRequest"
              >
                导入
              </nh-button>
            </el-upload>
            <nh-button
              class="template-import"
              @click="handleDownTemplate"
            >
              模板下载
            </nh-button>
          </slot>
        </div>
        <div class="import-btn-right">
          <slot name="titleBtnRight">
            <span v-if="dataAmount" class="data-amount-box">
              共
              <span class="data-amount">{{ dataAmount }}</span>
              条数据，</span>
            <nh-button
              v-if="errMsgList.length > 0"
              type="text"
              class="clear-btn"
              @click="clearAll"
            >
              全部清空
            </nh-button>
          </slot>
        </div>
      </div>
      <div>
        <slot name="describe" />
      </div>
      <div
        v-if="showContent"
        v-loading="loading.handleUploadHttpRequest"
        class="import-content"
      >
        <!-- 错误数据显示列表 -->
        <div v-if="errMsgList.length > 0">
          <slot name="contentTable">
            <PlTable
              v-model:pagination="pagination"
              :loading="loading.handleUploadHttpRequest"
              :data="pageTableData"
              :columns="tableColumn"
              height="250px"
              @update:pagination="handlePaginationChange"
            />
          </slot>
        </div>
        <!-- 无错误数据，并且未上传 -->
        <div v-if="errMsgList.length === 0 && !isUpload" class="no-data-box">
          <slot name="contentNoData">
            <div class="img-box">
              <img src="@/assets/noData.png">
            </div>
            <div class="no-data-explain">
              暂无数据，请导入
            </div>
          </slot>
        </div>
        <!-- 无错误数据，并且上传成功 -->
        <div v-if="errMsgList.length === 0 && isUpload" class="success-data-box">
          <slot name="contentSuccess">
            <i class="el-icon-success" />
            <div class="success-data-explain">
              数据验证成功
            </div>
          </slot>
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <slot name="footerSlot" />
          <nh-button @click="handleCancel">
            取消
          </nh-button>
          <nh-button
            :loading="confirmLoading"
            type="primary"
            @click="headleConfirm"
          >
            确定
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';

export default {
  name: 'DataImport',
  mixins: [loadingMixin],
  props: {
    errMsgList: {
      type: Array,
      default: () => [],
    },
    dataAmount: {
      type: Number,
      default: 0,
    },
    quantityLimit: {
      type: Number,
      default: 1000,
    },
    isUpload: {
      type: Boolean,
      default: false,
    },
    tableColumn: {
      type: Array,
      default: () => [
        {
          label: '序号',
          type: 'index',
          width: 50,
        },
        {
          label: '验证信息',
          prop: 'errorMsg',
          minWidth: 180,
        },
      ],
    },
    showContent: {
      type: Boolean,
      default: true,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submitSuccess', 'cancel', 'upload', 'downLoadTemplate', 'clearAll'],
  data() {
    return {
      // TABLECOLUMN: [{
      //   label: '验证信息',
      //   prop: 'errorMsg',
      //   width: 180,
      // }],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: {
        inboundOrderSave: false,
        handleUploadHttpRequest: false,
      },
      visible: false,
    };
  },
  computed: {
    pageTableData() {
      const start = this.pagination.size * (this.pagination.page - 1);
      const end = start + this.pagination.size;
      return this.errMsgList.slice(start, end);
    },
  },
  watch: {
    errMsgList(newval) {
      this.pagination.total = newval.length;
    },
  },
  methods: {
    // 打开弹窗
    showDialog() {
      this.visible = true;
    },
    // 关闭弹窗
    handleClose() {
      this.visible = false;
    },
    // 点击取消
    handleCancel() {
      this.visible = false;
      this.$emit('cancel');
    },
    // 页码更改
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
    },
    // 全部清空
    clearAll() {
      this.pagination.page = 1;
      this.$emit('clearAll');
    },
    // 点击确认
    headleConfirm() {
      this.$emit('submitSuccess');
    },
    // 上传
    async handleUploadHttpRequest(file) {
      const fd = new FormData();
      fd.append('file', file.file);
      await this.$emit('upload', fd);
    },
    handleUploadSuccess() {
      this.$refs.upload.clearFiles();
    },
    // 下载模板文件
    async handleDownTemplate() {
      await this.$emit('downLoadTemplate');
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-import-box {
  :deep(.dialog-import) {
    .el-dialog__header {
      padding-bottom: 20px;
    }

    .el-dialog__body {
      padding: 24px;
    }
  }

  .title-box {
    display: flex;
    align-items: center;

    .title-name {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .tips-box {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
  }

  .import-btn-box {
    display: flex;
    justify-content: space-between;

    .import-btn-left {
      display: flex;

      .template-import {
        margin-left: 10px;
      }
    }
  }

  .data-amount-box {
    color: #606266;

    .data-amount {
      color: #171717;
      font-weight: 600;
    }
  }

  .import-content {
    width: 100%;
    margin-top: 24px;
  }

  .no-data-box {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-box {
      width: 96px;
      height: 96px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .no-data-explain {
      margin-top: 25px;
    }
  }

  .success-data-box {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .el-icon-success {
      font-size: 70px;
      color: #52c41a;
    }

    .success-data-explain {
      font-size: 20px;
      font-weight: 500;
      margin-top: 35px;
    }
  }
}

</style>
