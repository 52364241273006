import { otmsHttp, bfsUserWebHttp, bfsSsoHttp } from '@/utils/http/index';
/**
 * 系统列表查询
 * https://yapi.ops.yunlizhi.cn/project/337/interface/api/18949
 */
export default function systemList(data) {
  return otmsHttp.post('permission_plus/system/list', data);
}

/**
 * 授权租户切换
 * @param {Object} data
 * @param {String} data.tenantId 租户ID
 * @param {String} data.tenantName 租户名称
 */
export const tenantChange = (data) => bfsUserWebHttp.request({
  url: '/tenant_user/own/change_tenant',
  method: 'post',
  data,
});

export function loginWithToken(data) {
  return bfsUserWebHttp.request({
    url: '/tenant_user/own/change_system',
    method: 'post',
    data,
  });
}
export function unifyLogin(data) {
  return bfsSsoHttp.request({
    url: '/unify_login',
    method: 'post',
    data,
  });
}
