import MainContent from '@/layout/MainContent.vue';

export default {
  path: '/report',
  component: MainContent,
  meta: { title: '报表中', icon: 'icon-baobiaozhongxin' },
  children: [
    {
      path: 'in-warehouse-details',
      meta: {
        title: '入库明细',
        trackNameZh: '报表中心/入库明细/列表页',
      },
      component: () => import('@/views/in-warehouse/in-warehouse-details/index.vue'),
    },
    {
      path: 'particulars',
      meta: {
        title: '出库明细',
        trackNameZh: '报表中心/出库明细/列表页',
      },
      component: () => import('@/views/out-warehouse/particulars/index.vue'),
    },
    {
      path: 'sales-and-inventory',
      meta: {
        title: '进销存报表',
      },
      component: () => import('@/views/in-library-management/inventory-management/sales-and-inventory/index.vue'),
    },
    {
      path: 'workload',
      meta: {
        title: '作业量报表',
        trackNameZh: '报表中心/作业量报表/列表页',
      },
      component: () => import('@/views/report/workload/index.vue'),
    },
    {
      path: 'working-performance',
      meta: {
        title: '员工作业效率',
        trackNameZh: '报表中心/员工作业效率/列表页',
      },
      component: () => import('@/views/report/working-performance/index.vue'),
    },
    {
      path: 'cancelled-detail',
      meta: {
        title: '挂单明细',
        trackNameZh: '报表中心/挂单明细/列表页',
      },
      component: () => import('@/views/report/cancelled-detail/index.vue'),
    },
  ],
};
