<template>
  <el-select
    v-bind="$attrs"
    filterable
    remote
    :clearable="clearable"
    :placeholder="placeholder"
    :remote-method="handleSearch"
    @visibleChange="handleVisibleChange"
  >
    <slot />
  </el-select>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '请输入关键词',
    },
    // 返回搜索结果
    remoteMethod: {
      type: Function,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const handleSearch = (keyword) => {
      props.remoteMethod(keyword);
    };

    const handleVisibleChange = (visible) => {
      if (visible) {
        // 显示下拉框选项时，重置选择项
        // fixbug: 输入关键字，查询到结果后，选择框失去焦点，再选中，下拉框的选项为上一次的搜索结果
        // 不使用blur事件的原因：如果失去焦点，搜索文字不为空，不应该重置搜索选项
        handleSearch();
      }
    };

    return {
      handleSearch,
      handleVisibleChange,
    };
  },
};
</script>
