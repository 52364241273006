import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:查询货主列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12400
 * @新文档: https://yapi.ops.yunlizhi.cn/project/388/interface/api/18865
 */
function shipperList(data) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/v2/list', data);
}

export default shipperList;

/**
 * @description:查询货主列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12400
 */
export function searchShipperList(data) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/v2/list', data);
}
