<template>
  <el-dialog
    custom-class="dialog-component"
    v-bind="$attrs"
    @open="handleOpen"
    @close="handleClose"
  >
    <template #title>
      <slot name="title">
        <title-text
          :title="title"
          size="small"
        />
      </slot>
    </template>
    <div class="component-dialog-body">
      <slot />
    </div>
    <template #footer>
      <slot name="footer" />
    </template>
  </el-dialog>
</template>

<script>
import TitleText from '../TitleText/TitleText.vue';

export default {
  components: {
    TitleText,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    track: {
      type: Object,
      default() { return {}; },
    },
  },
  emits: ['open', 'close'],
  data() {
    return {
      startTime: null,
    };
  },
  methods: {
    handleOpen() {
      this.startTime = Date.now();
      this.$emit('open');
    },
    handleClose() {
      if (this.track && this.track.name_zh) {
        window.YlTrack.report({
          trackType: 'view',
          duration_time: (Date.now() - this.startTime) / 1000,
          ...this.track,
        });
      }
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.dialog-component {
  min-width: 880px;

  .component-dialog-body {
    max-height: 380px;
    overflow: auto;
  }
}

</style>
