<template>
  <table class="chan-configuration-layout__drop-table" :style="componentStyle">
    <!-- 表头、页眉 -->
    <thead>
      <tr>
        <th v-for="header in columns" :key="header.label">
          {{ header.label }}
        </th>
      </tr>
    </thead>
    <!-- / 表头、页眉 -->

    <!-- table正文、主体 -->
    <!-- <tbody>
      <tr v-for="(item, index) in list" :key="index">
        <template v-for="(header, headerIndex) in item.headers || headers">
          <td
            v-if="!header.hide"
            :key="headerIndex"
            class="chan-table-td"
            :class="[header.class, { 'relative-td': header.children }]"
            :style="setStyle(header, item)"
            :colspan="header.colspan || 1"
          >
            <slot :name="header.slot" :row="item" />
            <span v-if="!header.slot" v-html="formatTdText(header, item, index)" />

            <div v-if="header.children" class="child-td">
              <div
                v-for="(child, childIndex) in header.children"
                :key="childIndex"
                v-html="child.prop && item[child.prop] ? item[child.prop] : ''"
              />
            </div>
          </td>
        </template>
      </tr>
    </tbody> -->
    <!-- / table正文、主体 -->
  </table>
</template>
<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'ChanDropTable',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    style: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
  //   const componentData = computed(()=> {
  //     return {
  //       ...props.data
  //     }
  //   })
    const componentStyle = computed(() => ({
      // display: 'inline-block',
      // fontSize: props.fontSize,
      // textAlign: props.textAlign,
      // width: props.width,
      // height: props.height,
      ...props.style,
    }));
    //   //methods
    //   const handleDragStart = (event) => {
    //     emit('dragstart', event)
    //   }

    return {
      // componentData,
      componentStyle,
      // handleDragStart,
    };
  },
});
</script>
