export default {
  data() {
    return {
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
    };
  },
};
