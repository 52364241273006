<template>
  <container :title="$attrs.title || $route.meta.title">
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidth"
      :model="data"
      class="header-component"
    >
      <el-form-item
        v-for="(item) in $info"
        :key="item.prop"
        :label="item.label"
        class="form-item"
        :style="item.componentStyle"
      >
        <span>{{ data[item.prop] }}</span>
      </el-form-item>
    </el-form>
  </container>
</template>

<script>
import Container from '../Container/Container.vue';

export default {
  name: 'InfoHeader',
  components: { Container },
  props: {
    info: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    col: {
      type: Number,
      default: 3,
    },
    minWidth: {
      type: String,
      default: '300px',
    },
    labelPosition: {
      type: String,
      default: 'right', // right、left
    },
    labelWidth: {
      type: String,
      default: '120px',
    },
  },
  computed: {
    $info() {
      // 宽度设置为数字，参考栅格布局。在没设置col情况，默认3 分栏
      return this.info.map(((item) => {
        let width = 1;
        if (item.width && Number.isInteger(item.width)) {
          if (item.width > this.col) {
            width = this.col;
          } else if (item.width > 0) {
            width = item.width;
          }
        }
        return {
          ...item,
          componentStyle: {
            minWidth: this.minWidth,
            width: `${(100 / this.col) * width}%`,
          },
        };
      }));
    },
    labelStyle() {
      return {
        textAlign: this.labelPosition,
        width: this.labelWidth,
      };
    },
  },
};
</script>
<style lang="scss">

.dialog-header {
  margin-bottom: 0;
  box-shadow: 0 0 0 0 rgb(0 25 55 / 10%);
  padding: 0;
}

.header-component {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  .form-item {
    white-space: nowrap;
    margin-bottom: 0;
    padding: 8px 16px;
    overflow: hidden;

    span {
      font-weight: 600;
      color: #171717;
    }
  }
}
</style>
