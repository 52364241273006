<template>
  <div class="sub-title-box">
    <div :class="`sub-title-Icon ${titleIconName}`" />
    <div class="sub-title-text" v-text="title" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      require: true,
      type: String,
      default: '',
    },
    titleIconName: {
      type: String,
      default: 'sub-title-line',
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';

.sub-title-text {
  font-size: 16px;
  font-weight: 600;
  color: $title_text_color;
}

.sub-title-box {
  display: flex;
  align-items: center;

  .sub-title-Icon {
    color: $item_color;
    width: 14px;
    height: 13.9px;
    margin-right: 10px;
  }

  .sub-title-line {
    width: 4px;
    height: 18px;
    border-radius: 2px;
    background: $item_color;
  }
}
</style>
