<template>
  <component
    :is="item.tagName"
    v-for="item in dropItems"
    :key="item.key"
    v-bind="configuration[item.key]"
    :class="{
      selected: item.key === selectedDropItem.key
    }"
    @click="handleDropItemClick(item)"
  />
</template>
<script>
import { defineComponent, computed } from 'vue';

import EditorText from '../text/index.vue';
import EditorTable from '../table/editor.vue';

const TAG_PREFIX = 'editor-';

export default defineComponent({
  components: {
    EditorText,
    EditorTable,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const tagName = computed(() => `${TAG_PREFIX}${props.type}`);

    return {
      tagName,
    };
  },
});
</script>
