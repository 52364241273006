<template>
  <el-pagination
    v-bind="{...$attrs, ...$props}"
    :current-page="$attrs.page"
    :page-size="$attrs.size"
    @size-change="onSizeChange"
    @current-change="onPageChange"
  />
</template>

<script>
export default {
  props: {
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 50, 100];
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
  },
  emits: ['size-change', 'current-change', 'change'],
  methods: {
    onSizeChange(size) {
      this.$emit('size-change', size);
      this.$emit('change', {
        size,
        page: 1,
      });
    },
    onPageChange(page) {
      this.$emit('current-change', page);
      this.$emit('change', {
        size: this.$attrs.size,
        page,
      });
    },
  },
};
</script>
