import MainContent from '@/layout/MainContent.vue';
import KeepAliveLayout from '@/layout/KeepAliveLayout.vue';

import ExceptionCenter from '@/constant/paths/exceptionCenter';

export default {
  path: ExceptionCenter.ExceptionCenter.fullPath,
  component: MainContent,
  meta: { title: '异常中心', icon: 'icon-yichang' },
  children: [
    {
      path: ExceptionCenter.InboundOrder.path,
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '入库订单',
            catchName: ExceptionCenter.InboundOrder.catchName,
            trackNameZh: '异常中心/入库订单/列表页',
          },
          component: () => import('@/views/device-exception-center/inbound-order-exception/index.vue'),
        },
      ],
    },
    {
      path: 'auto-device-exception',
      meta: { title: '分拣设备异常' },
      component: () => import('@/views/device-exception-center/auto-device-exception/index.vue'),
    },
  ],
};
