import { menuRouters } from '@/router/route';

const mutations = {
  setMenu(state, menu = []) {
    state.menu = menu.map((item) => {
      const current = menuRouters.find((route) => route.path === item.path);
      return {
        ...item,
        icon: current?.meta?.icon,
      };
    });
  },
};

export default mutations;
