const BASE = 'Base';

const OWNER_ABOUT = `${BASE}OwnerAbout`;
const WAREHOUSE_ABOUT = `${BASE}WarehouseAbout`;
const HUMAN_RESOURCE = `${BASE}HumanResourceManagement`;

export default {
  OWNER: `${OWNER_ABOUT}Owner`,
  GOODS: `${OWNER_ABOUT}Goods`,
  WAREHOUSE: `${WAREHOUSE_ABOUT}Warehouse`,
  SCHEDULING_PLAN: `${HUMAN_RESOURCE}SchedulingPlan`,
};
