<template>
  <el-container class="left-right-layout">
    <PlMenu :menu="menu" :default-active="activeMenu">
      <template #textLogo>
        <img
          src="@/assets/logo/logo-white-new.png"
          class="imgStyle"
          @click.stop="goInternal"
        >
        <div class="changeBox">
          <div class="left">
            <img src="@/assets/logo/logo-mini.png">
            {{ systemName }}
          </div>
          <drop-down :list="systemList" is-open />
        </div>
      </template>
      <template #minLogo>
        <img
          src="@/assets/logo/logo-white-mini.png"
          class="miniLogo"
        >
        <div class="minSelect">
          <drop-down :list="systemList" :is-open="false" />
        </div>
      </template>
    </PlMenu>

    <el-container class="right-container">
      <NavBar @warehouseChanged="reload" />

      <router-view v-if="isRouterAlive" class="flex_1" />
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from 'vuex';
import ExceptionCenter from '@/constant/paths/exceptionCenter';
import systemList from '@/api/auth';
import NavBar from './components/NavBar.vue';
import dropDown from './components/dropDown.vue';
import SYSTEMS from './config';

const RECEIPT_ORDER_COUNT = 'receiptOrderCount';
const RECEIPT_CENTER_COUNT = 'exceptionCenterCount';

export default {
  name: 'LeftRightLayout',
  components: {
    NavBar,
    dropDown,
  },
  data() {
    return {
      actionMenu: this.$route.path,
      pagePaths: [],
      isRouterAlive: true,
      isSideBarCollapse: false,
      clickNum: 0,
      menuConfig: [
        {
          path: ExceptionCenter.ExceptionCenter.fullPath,
          countKey: RECEIPT_CENTER_COUNT,
        },
        {
          path: ExceptionCenter.InboundOrder.fullPath,
          countKey: RECEIPT_ORDER_COUNT,
        },
      ],
      systemList: [],
    };
  },
  computed: {
    ...mapState({
      menuList: ({ menu }) => menu.menu,
      accountInfo: ({ user }) => user.accountInfo,
    }),
    activeMenu() {
      return this.findActiveMenu(this.$route.path);
    },
    menu() {
      this.loopMenu(this.menuList, (submenu) => {
        const current = submenu;
        const config = this.menuConfig.find((item) => item.path === submenu.path);
        if (config) {
          current.count = this.$store.state.app[config.countKey];
        }
      });
      return this.menuList;
    },
    systemName() {
      const system = this.systemList.find((item) => item.systemCode === 'wms2.0');
      if (system) {
        return system.label;
      }
      return '';
    },
  },
  watch: {
    menuList() {
      // 查找所有能跳转页面的菜单
      this.pagePaths = this.findPagePath();
    },
  },
  created() {
    this.initMenu();
    this.handleGetTenant();
  },
  methods: {
    // 获取系统列表
    async handleGetTenant() {
      const resp = await systemList({});
      this.systemList = resp
        .filter((item) => SYSTEMS.includes(item.systemCode))
        .map((list) => ({
          systemCode: list.systemCode, // 系统code
          value: list.ip,
          label: list.name,
        }));
    },
    initMenu() {
      this.initMenuList();
      // 获取异常中心的异常数量
      this.initReceiptCount();
    },
    initMenuList() {
      this.$store.dispatch('menu/update');
    },
    // 获取异常中心的异常数量
    initReceiptCount() {
      // 获取入库异常数量
      this.$store.dispatch('app/setReceiptOrderCount', {
        warehouseId: this.accountInfo?.warehouseId,
        handleStatus: 'NONE_HANDLE',
      });
    },
    reload() {
      this.tiggleRouterAlive();
      this.$nextTick(() => {
        this.tiggleRouterAlive();
      });
    },
    tiggleRouterAlive() {
      this.isRouterAlive = !this.isRouterAlive;
    },
    findPagePath() {
      const pagePaths = [];

      this.findLastMenu(this.menuList, (submenu) => {
        pagePaths.push(submenu.path);
      });

      return pagePaths;
    },
    // 查找所有能跳转页面的菜单
    findLastMenu(menu, callback) {
      menu.forEach((submenu) => {
        if (submenu.children?.length === 0) {
          if (callback) {
            callback(submenu);
          }
        }

        this.findLastMenu(submenu.children, callback);
      });
    },
    loopMenu(menu, callback) {
      menu.forEach((submenu) => {
        if (submenu.children?.length > 0) {
          this.loopMenu(submenu.children, callback);
        }

        if (callback) {
          callback(submenu);
        }
      });
    },
    // 查找所有能跳转页面的菜单
    findActiveMenu(path) {
      if (this.pagePaths.length === 0 || this.pagePaths.includes(path)) {
        return path;
      }

      const parentPath = path.substring(0, path.lastIndexOf('/'));
      return this.findActiveMenu(parentPath);
    },
    goInternal() {
      this.clickNum += 1;
      if (this.clickNum >= 7) {
        this.clickNum = 0;
        this.$router.push({
          path: '/config-management/rule-batch-attribute/internal-interface-test',
          query: {},
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.dm-side .logo-label) {
  padding-bottom: 16px;
}

.left-right-layout {
  background-color: #f6f8fa;
}

.el-container {
  height: 100%;
}

.right-container {
  margin: 0 24px;
  flex-direction: column;
}

.el-main {
  background-color: #f6f6f6;
}

.el-dropdown-link {
  cursor: pointer;
  color: #969799;
}

.sidebar-switch-lists {
  box-sizing: border-box;
  padding: 0;
  width: 180px;
}

.changeBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 37px 17px 0;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    line-height: 24px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-color: #fff;
      border-radius: 3px;
    }
  }
}

.minSelect {
  margin-top: 37px;
}

.imgStyle {
  width: 180px;
  height: 30px !important;
}

</style>
