<!--
 * @Author: your name
 * @Date: 2021-10-29 21:39:04
 * @LastEditTime: 2021-10-31 10:52:56
 * @LastEditors: your name
 * @Description: In User Settings Edit
-->
<template>
  <div :class="[...classLine, 'drawer-container']">
    <h4 class="drawer-container-title">
      <slot name="title">
        {{ title }}
      </slot>
    </h4>
    <slot />
  </div>
</template>
<script>

export default {
  name: 'DrawerContainer',
  props: {
    title: {
      type: String,
      required: true,
    },
    line: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    classLine() {
      return this.line.map((item) => `line-${item}`);
    },
  },
};
</script>
<style lang="scss">
.drawer-container {
  &.line-bottom {
    margin-bottom: 8px;
    border-bottom: 1px solid #ebedf0;
  }

  &.line-top {
    padding-top: 8px;
    border-top: 1px solid #ebedf0;
  }

  .drawer-container-title {
    position: relative;
    margin-bottom: 8px;
    padding-left: 11px;
    font-size: 14px;
    font-weight: 600;
    color: #323233;
    line-height: 22px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      height: 16px;
      width: 3px;
      transform: translateY(-50%);
      background-color: #06b880;
    }
  }
}

</style>
