import ExceptionCenterPathNames from '@/constant/pageNames/exceptionCenter';

const ExceptionCenter = {
  path: 'device-exception-center',
  fullPath: '/device-exception-center',
};

const InboundOrder = {
  catchName: ExceptionCenterPathNames.INBOUND_ORDER,
  path: 'inbound-order-exception',
  fullPath: `${ExceptionCenter.fullPath}/inbound-order-exception`,
};

export default {
  ExceptionCenter,
  InboundOrder,
};
