import {
  LEFT, CENTER, RIGHT,
} from './constants';

export const positionConfig = {
  [LEFT]: '靠左',
  [CENTER]: '居中',
  [RIGHT]: '靠右',
};

export const positionOptions = Object.keys(positionConfig).map((key) => ({
  value: key,
  label: positionConfig[key],
}));

export const fontSizeOptions = ['12px', '13px', '14px', '15px', '16px', '20px'];

export const defaultData = {};

export const defaultColumns = [];

export const defaultStyle = {
  textAlign: CENTER,
  minHeight: '26px',
  width: '100%',
  fontSize: fontSizeOptions[2],
};
