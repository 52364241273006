<template>
  <component :is="tagName" v-bind="$attrs" />
</template>
<script>
import { defineComponent, computed } from 'vue';

import EditorText from '../text/index.vue';
import EditorTable from '../table/editor.vue';
import EditorGoodsBill from '../goodsBill/editor.vue';

const TAG_PREFIX = 'editor-';

export default defineComponent({
  components: {
    EditorText,
    EditorTable,
    EditorGoodsBill,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const tagName = computed(() => `${TAG_PREFIX}${props.type}`);

    return {
      tagName,
    };
  },
});
</script>
