import MainContent from '@/layout/MainContent.vue';

export default {
  path: '/config-management',
  component: MainContent,
  meta: { title: '配置管理', icon: 'icon-1peizhi' },
  children: [{
    path: 'rule-batch-attribute',
    meta: {
      title: '批属性规则',
      trackNameZh: '配置管理/批属性规则/列表页',
    },
    component: () => import('@/views/config-management/rule-batch-attribute/index.vue'),
  },
  {
    path: 'rule-batch-attribute/internal-interface-test',
    meta: {
      title: '内部调试',
      trackNameZh: '配置管理/内部调试/列表页',
    },
    component: () => import('@/views/config-management/internal-interface-test/index.vue'),
  },
  {
    path: 'goods-storage-setup',
    meta: {
      title: '货品存放设置',
      trackNameZh: '配置管理/货品存放设置/列表页',
    },
    component: () => import('@/views/config-management/goods-storage-setup/index.vue'),
  },
  {
    path: 'print',
    meta: { title: '打印单' },
    component: () => import('@/views/config-management/print/index.vue'),
  },
  ],
};
