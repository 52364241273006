<template>
  <el-col :span="span" class="dialog-form-component">
    <el-form-item v-bind="$attrs">
      <slot />
    </el-form-item>
  </el-col>
</template>

<script>
export default {
  props: {
    span: {
      type: Number,
      default: 12,
    },
  },
};
</script>

<style lang="scss">
.dialog-form-component {
  .el-input {
    width: 240px;
  }

  .el-textarea {
    width: 660px;
  }
}
</style>
