<template>
  <el-select
    class="tenant-selector-component"
    :value="value"
    filterable
    @change="onChange"
  >
    <el-option
      v-for="warehouse in warehouses"
      :key="warehouse.warehouseId"
      :value="warehouse.warehouseId"
      :label="warehouse.warehouseName"
    />
  </el-select>
</template>

<script>

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    // 可选择的租户
    warehouses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['change'],
  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>
<style lang="scss">
.tenant-selector-component {
  width: 280px;
  box-shadow: 0 2px 4px 0 rgba(0, 25, 55, 0.1);
  border-radius: 6px;

  .el-input__inner {
    border: 0;
  }
}
</style>
