<template>
  <el-select
    :model-value="selectedShipper"
    v-bind="$attrs"
    value-key="shipperId"
    :clearable="clearable"
    filterable
    :filter-method="handleFilter"
    placeholder="请输入货主名称或编号"
    @update:model-value="handleChange"
    @visible-change="handleVisibleChange"
  >
    <el-option
      v-for="item in shipperOptions"
      :key="item.shipperId"
      :label="item.shipperName"
      :value="item"
    >
      <span class="shipper-selector__name">{{ item.shipperName }}</span>
      <span class="shipper-selector__code">{{ item.customerCode }}</span>
    </el-option>
  </el-select>
</template>

<script>

import useShipper from './useShipper';
import useShipperOptions from './useShipperOptions';

export default {
  props: {
    shipper: {
      type: Object,
      default: () => ({}),
    },
    shipperId: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:shipper', 'update:shipperId'],
  setup(props, { emit }) {
    const {
      selectedShipper,
    } = useShipper(props);

    const {
      shipperOptions,
      setFilterKeyword,
    } = useShipperOptions();

    const handleChange = (shipper) => {
      const current = shipper || {};

      emit('update:shipper', { ...current });
      emit('update:shipperId', current.shipperId);
    };

    const handleFilter = (keyword) => {
      setFilterKeyword(keyword);
    };

    const handleVisibleChange = (visible) => {
      if (visible) {
        // 显示下拉框的时候，重置选项
        const filterKeyword = '';
        setFilterKeyword(filterKeyword);
      }
    };

    return {
      selectedShipper,
      shipperOptions,
      handleFilter,
      handleChange,
      handleVisibleChange,
    };
  },
};
</script>

<style lang="scss">
.shipper-selector__name {
  display: inline-block;
  min-width: 55%;
}
</style>
