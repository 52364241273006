const mutations = {
  setDictionary(state, dictionary = []) {
    state.dictionary = dictionary;
  },
  getDictionaryByCode: ({ state }, code) => {
    const current = state.dictionary.find((item) => item.dictionaryCode === code);
    return current?.dictionaryValues;
  },
  setReceiptOrderCount(state, count = 0) {
    state.receiptOrderCount = count;
  },
  updateExceptionCenterCount(state) {
    // 异常中心数量 = 入库单异常数量+出库单异常数量（目前没有）
    state.exceptionCenterCount = state.receiptOrderCount;
  },
};

export default mutations;
