const formConfig = {};

const formFieldConfig = {
  'el-date-picker': {
    valueFormat: 'x', // 日期选择器组件，时间格式默认用 timestamp 格式
  },
  'el-input': {
    clearable: true,
  },
  'el-select': {
    clearable: true,
  },
};

const tableConfig = {};

const platoConfig = {
  form: { ...formConfig },
  formField: {
    ...formFieldConfig,
  },
  table: { ...tableConfig },
  pagination: {
    layout: 'total, sizes, prev, pager, next, jumper',
    pageSizes: [10, 50, 100, 400],
  },
};

export default platoConfig;
