<template>
  <component
    :is="field.component"
    v-if="NoSlotComponents.includes(field.component) && !isDetail"
    v-model="formData[field.prop]"
    v-bind="field.componentAttrs"
  />
  <component
    :is="field.component"
    v-else
    v-model="formData[field.prop]"
    v-bind="field.componentAttrs"
  >
    <FieldSub :parent-name="field.component" :options="field.options" />
  </component>
</template>
<script >
import { defineProps, toRefs } from 'vue';
import FieldSub from './FieldSub/field-sub.vue';
import NoSlotComponents from './constant';

export default {
  name: 'FormComponent',
};
</script>

<script setup>
const props = defineProps({
  form: {
    type: Object,
    default: () => ({}),
  },
  // 表单字段
  field: {
    type: Object,
    default: () => ({}),
  },
});
const { form: formData } = toRefs(props);
</script>

<style lang="scss">
.basic-form .el-input-group__append {
  background-color: #fff;

  .el-form-item {
    margin: 0;

    .el-form-item__content {
      line-height: 30px;
    }
  }

  .el-form-item.is-error {
    .el-input__suffix {
      box-shadow: 0 0 0 1px #f56c6c;
    }
  }

  .el-input {
    width: 80px;
  }
}
</style>
