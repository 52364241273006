import { ref, reactive } from 'vue';
import env from '../../../env/index';

const upLoadUrl = `${env.OTMS_WEB_PLUS_SERVER}/bfs_oss/upload`;

const uploadData = ref({
  fileExtension: 'png',
});
const progressList = ref([]); // 数据
const uploadFileList = ref([]); // element 上传数据

const loading = reactive({
  getUploadFiles: false,
});

export default () => ({
  upLoadUrl,
  uploadData,
  progressList,
  uploadFileList,
  loading,
});
