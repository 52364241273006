import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      // 通过 dictionary 获取数据字典中的 ZONE_TEMPERATURE_LAYER_CODE ，返回格式{dictionaryCode: dictionaryValue}
      $dictionary: ({ app }) => app.dictionary,
    }),
    // 通过 dictionaryFiledMap 获取数据字典中的 ZONE_TYPE_CODE ，返回格式[{value: code, label: name}]
    $dictionaryFiledMap() {
      const filedMap = {};
      this.$dictionary.forEach(({ dictionaryCode, dictionaryValues }) => {
        filedMap[dictionaryCode] = dictionaryValues.map((item) => ({
          value: item.code,
          label: item.name,
        }));
      });

      return filedMap;
    },
  },
};
