import { USER, ACCOUNT, LOGIN_PARAMS } from '@/constant/storage';
import { localStorageSet } from '@/utils/localStorage';
import { setToken } from '@/utils/auth';

const mutations = {
  // 设置账号信息，包括token、当前仓库、用户名、密码等
  setAccountInfo(state, accountInfo = null) {
    if (accountInfo) {
      state.accountInfo = {
        ...accountInfo,
        warehouseId: Number(accountInfo.ext.warehouseId),
      };
    } else {
      state.accountInfo = accountInfo;
    }
    localStorageSet(ACCOUNT, state.accountInfo);
    // @左柳波 2021年4月20日 说，统一用新的token，如果api不兼容，后端修改，方法：api添加一句代码，告诉认证接口兼容老token
    setToken(accountInfo?.token);
  },
  setUserInfo(state, userInfo = null) {
    state.userInfo = userInfo;
    localStorageSet(USER, state.userInfo);
  },
  setLoginParams(state, loginParams = {}) {
    state.loginParams = loginParams;
    localStorageSet(LOGIN_PARAMS, loginParams);
  },
};

export default mutations;
