export default function useVxeTable() {
  const handleAdd = async ($ref, list, defaultValue = {}) => {
    const newRecord = { ...defaultValue };
    const { row: newRow } = await $ref.insert(newRecord);
    await $ref.setActiveRow(newRow);
    list.unshift(newRow);
  };

  const handleRemove = ($ref, row, list) => {
    const index = list.indexOf(row);
    $ref.remove(row);
    list.splice(index, 1);
  };

  const handleEdit = ($ref, row, field) => {
    // 判断单元格值是否被修改
    if ($ref.isUpdateByRow(row, field)) {
      setTimeout(() => {
        // 局部更新单元格为已保存状态
        $ref.reloadRow(row, null, field);
      }, 300);
    }
  };

  return {
    handleAdd,
    handleRemove,
    handleEdit,
  };
}
