<!--
 * @Author: 郑君婵
 * @Date: 2020-08-04 15:05:25
 * @LastEditTime: 2020-10-14 10:52:23
 * @Description: 问号帮助提示框
 * @FilePath: /src/components/ChanHelpPopover/ChanHelpPopover.vue
-->
<template>
  <el-popover
    :title="title"
    placement="top-start"
    trigger="hover"
  >
    <div class="popover-content">
      <slot />
    </div>
    <template #reference>
      <i class="el-icon-question" />
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    title: {
      default: '帮助',
      type: String,
    },
  },
};
</script>
