import KeepAliveLayout from '@/layout/KeepAliveLayout.vue';

import OutWarehouse from '@/constant/pageNames/outWarehouse';

export default {
  path: '/out-warehouse',
  component: () => import('@/views/out-warehouse/index.vue'),
  meta: { title: '出库管理', icon: 'icon-warehouseOut' },
  children: [
    {
      path: 'deliver-order',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '出库单管理',
            catchName: OutWarehouse.DELIVER_ORDER,
            trackNameZh: '出库管理/出库单管理/列表页',
          },
          component: () => import('@/views/out-warehouse/deliver-order/index.vue'),
        },
      ],
    },
    {
      path: 'pickup-plan',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '预约提货计划',
            catchName: OutWarehouse.PICKUP_PLAN,
            trackNameZh: '出库管理/预约提货计划/tab页',
          },
          component: () => import('@/views/out-warehouse/pickup-plan/index.vue'),
        },
      ],
    },
    {
      path: 'wave-order',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '波次管理',
            catchName: OutWarehouse.WAVE_ORDER,
            trackNameZh: '出库管理/波次管理/列表页',
          },
          component: () => import('@/views/out-warehouse/wave-order/index.vue'),
        },
      ],
    },
    {
      path: 'sorting-manage/sorting-task',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '分拣任务',
            catchName: OutWarehouse.SORTING_TASK,
            trackNameZh: '',
          },
          component: () => import('@/views/out-warehouse/sorting-manage/sorting-task/index.vue'),
        },
      ],
    },
    {
      path: 'pre-audit',
      meta: {
        title: '线路发运',
        trackNameZh: '出库管理/线路发运/tab页',
      },
      component: () => import('@/views/out-warehouse/pre-audit/index.vue'),
    },
    {
      path: 'sorting-manage/distribution-goods',
      meta: { title: '货品分布' },
      component: () => import('@/views/out-warehouse/sorting-manage/distribution-goods/index.vue'),
    },
    {
      path: 'direct-ship',
      meta: {
        title: '发运-按订单',
        trackNameZh: '出库管理/发运-按订单/列表页',
      },
      component: () => import('@/views/out-warehouse/direct-ship/index.vue'),
    },
  ],
};
