<template>
  <div class="drop-print">
    <div class="drop-print__header">
      <img :style="logoStyle" src="@/assets/logo/logo-green.png">
      <div class="drop-print__header__barcodes">
        <nh-barcode
          v-for="item in billHeader.barcode.data"
          :key="item.codeProp"
          class="drop-view__barcode"
          :value="data[item.codeProp]"
          :text="item.textProp && data[item.textProp]"
        />
      </div>
      <div :style="titleStyle">
        {{ billHeader.title.data.text }}
      </div>
    </div>
    <div>
      <div
        v-for="item in billHeader.info.fields"
        :key="item.label"
        class="order-info-item"
      >
        <span v-text="item.label" />：<span v-text="data[item.prop]" />
      </div>
    </div>

    <table
      class="goods-bill-table border"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      :style="billTable.style"
    >
      <!-- table标题 -->
      <!-- <caption>
        <slot name="title" />
      </caption> -->
      <!-- / table标题 -->

      <!-- 表头、页眉 -->
      <thead>
        <tr>
          <th
            v-for="header in billTable.columns.fields"
            :key="header.label"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <!-- / 表头、页眉 -->

      <!-- table正文、主体 -->
      <tbody>
        <tr v-for="(item, index) in data[billTable.prop]" :key="index">
          <td
            v-for="(header, headerIndex) in billTable.columns.fields"
            :key="headerIndex"
          >
            {{ item[header.prop] }}
          </td>
        </tr>
      </tbody>
      <!-- / table正文、主体 -->

      <!-- 表尾、页脚 -->
      <!-- <tfoot v-if="footers.length">
        <tr>
          <td
            v-for="(footer, footerIndex) in footers"
            :key="footerIndex"
            :width="footer.width"
            :colspan="footer.colspan || 1"
            v-html="footer.label"
          />
        </tr>
      </tfoot> -->
    <!-- / 表尾、页脚 -->
    </table>
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({
        customerName: '徐福记',
        customerOrderNo: 'WV2021070600000',
        deliverOrderNo: 'OB2021070600008',
        stationName: '徐福记',
        customerStationCode: '徐福记',
        lineName: '徐福记',
        goods: [
          {
            shipperGoodsCode: '徐福记',
            goodsName: '徐福记',
            spec: '徐福记',
            index: '徐福记',
            temperatureLayerTxt: '徐福记',
            shelfLife: '徐福记',
          },
        ],
      }),
    },
    billHeader: {
      type: Object,
      default: () => ({}),
    },
    billTable: {
      type: Object,
      default: () => ({}),
    },
    style: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const componentData = computed(() => ({
      ...props.data,
    }));
    const logoStyle = computed(() => ({
      position: 'absolute',
      top: 0,
      left: 0,
      ...props.billHeader.logo.style,
    }));
    const titleStyle = computed(() => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...props.billHeader.title.style,
    }));
    // methods
    const handleDragStart = (event) => {
      emit('dragstart', event);
    };

    return {
      componentData,
      logoStyle,
      titleStyle,
      handleDragStart,
    };
  },
});
</script>

<style lang="scss">
.order-info-item {
  display: inline-block;
  width: 33.333%;
}

.goods-bill-table {
  &.border {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    tr:first-child {
      th {
        border-top: 1px solid #000;
      }
    }

    td,
    th {
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      &:last-child {
        border-right: 0;
      }
    }
  }
}
.drop-print {
  .drop-print__header {
    position: relative;
  }

  .drop-print__header__barcodes {
    position: absolute;
    right: 0;
    top: 0;
  }

  .drop-view__barcode {
    & + .drop-view__barcode {
      margin-left: 10px;
    }
  }
}
</style>
