const dbName = 'yunlizhiDB';
const dbVersion = 1;

let tables = [];
const ctx = require.context('./table', false, /.*\.js$/);
ctx.keys().forEach((key) => {
  tables = tables.concat(ctx(key).default);
});

class InitDB {
  #indexedDB = window.indexedDB
    || window.webkitindexedDB
    || window.msIndexedDB
    || window.mozIndexedDB;

  #DB = null;

  constructor() {
    this.#init().then((db) => {
      this.#DB = db;
    });
  }

  #init() {
    return new Promise((resolve, reject) => {
      const request = this.#indexedDB.open(dbName, dbVersion);
      request.onerror = () => {
        reject();
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        // 判断表格是否存在, 如果不存在新建
        for (let i = 0, l = tables.length; i < l; i += 1) {
          if (!db.objectStoreNames.contains(tables[i].name)) {
            db.createObjectStore(tables[i].name, {
              autoIncrement: true,
            });
          }
        }
      };
    });
  }

  #getStore(tableName, mode) {
    // 事务,任何对于数据库中数据的读和修改的操作只能在事务中进行。
    const ts = this.#DB.transaction(tableName, mode);
    // 通过事务对象去获取对象仓库
    return ts.objectStore(tableName);
  }

  /**
   * 添加和修改数据
   * @param {*} value
   * @param {*} key
   */
  async put(data, key, tableName) {
    if (!this.#DB) {
      this.#DB = await this.#init();
    }
    return new Promise((resolve, reject) => {
      const store = this.#getStore(tableName, 'readwrite');
      const request = store.put(data, key);
      request.onsuccess = () => {
        resolve();
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
    * 删除数据
    */
  async delete(key, tableName) {
    if (!this.#DB) {
      this.#DB = await this.#init();
    }
    return new Promise((resolve, reject) => {
      const store = this.#getStore(tableName, 'readwrite');
      const request = store.delete(key);
      request.onsuccess = () => {
        resolve();
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
    * 查询数据
    */
  async get(key, tableName) {
    if (!this.#DB) {
      this.#DB = await this.#init();
    }
    return new Promise((resolve, reject) => {
      const store = this.#getStore(tableName, 'readonly');
      let request;
      if (key) {
        request = store.get(key);
      } else {
        request = store.getAll();
      }
      request.onsuccess = (res) => {
        resolve(res.target.result);
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
    * 删除表
    */
  async clear(tableName) {
    if (this.#DB) {
      this.#DB = await this.#init();
    }
    return new Promise((resolve, reject) => {
      const store = this.#getStore(tableName, 'readwrite');
      const request = store.clear();
      request.onsuccess = () => {
        resolve();
      };
      request.onerror = () => {
        reject();
      };
    });
  }
}

export default InitDB;
