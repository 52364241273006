import { createRouter, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress';
import { setToken, setLongToken, getToken } from '@/utils/auth';
import { loginWithToken } from '@/api/auth';
import { localStorageSet, localStorageGet } from '@/utils/localStorage';
import handlePermission from './permission';
import routes from './route';
import env from '../../env/index';
import store from '../store/index';

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 白名单，不需要登录验证
const whiteList = ['/login', '/warehouse', '/print/delivery-list', '/print/warehouse-receipt-list'];
const WarehousePath = '/warehouse';
/**
 * 返回登录页面
 */
const toLoginPage = (next) => {
  if (process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'qa') {
    const loginUrl = localStorageGet('loginUrl') || env.LOGIN_URL;
    window.location = `${loginUrl}/#/rootLogin`;
  } else {
    next({ path: '/login' });
  }
};

const setShortToken = async (to, from, next) => {
  const data = {
    tenantId: to.query.id,
    tenantName: to.query.name,
    clientCode: 'wms_web',
  };
  const { token } = to.query;
  setLongToken(token);
  try {
    const user = await loginWithToken(data);
    setToken(user.token);
    const ext = user.ext ? JSON.parse(user.ext) : null;
    if (ext) {
      setLongToken(ext.legacyToken);
    }
    const loginUrl = (to.query && to.query.loginUrl) || 'https://account.yunlizhi.cn';
    localStorageSet('loginUrl', loginUrl);
    store.commit('user/setAccountInfo', { ...user, ext });
    store.commit('user/setUserInfo', { ...user, ext });
    next({ path: '/' });
  } catch (e) {
    toLoginPage(next);
  }
};
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to.query.token) {
    await setShortToken(to, from, next);
  } else {
    const { tag } = to.query;
    const isIframeWareHousePath = tag && (to.path === WarehousePath);
    const hasToken = getToken() || isIframeWareHousePath;
    if (hasToken) {
      const route = handlePermission(to, from, next);
      next(route);
    } else if (whiteList.includes(to.path)) {
      next();
    } else {
      toLoginPage(next);
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // 进度条结束
});

export default router;
