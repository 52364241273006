import { getDictionaryFromWms } from '@/api/base';
import { getReceiptOrderCount } from '@/views/device-exception-center/inbound-order-exception/api';

const actions = {
  async updateDictionary({ commit }) {
    const dictionary = await getDictionaryFromWms();
    commit('setDictionary', dictionary);
  },
  searchDictionaryByCode: ({ state }, code) => {
    const current = state.dictionary.find((item) => item.dictionaryCode === code);
    return current?.dictionaryValues;
  },
  // 入库异常数据
  async setReceiptOrderCount({ commit }, params) {
    const { count } = await getReceiptOrderCount(params);
    commit('setReceiptOrderCount', count);
    commit('updateExceptionCenterCount');
  },
};

export default actions;
