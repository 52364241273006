<template>
  <span :class="`title-text-component title--${size}`" v-text="title" />
</template>

<script>
export default {
  props: {
    title: {
      require: true,
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';

.title-text-component {
  font-size: 18px;
  font-weight: 600;
  color: $title_text_color;
}

.title {
  &--medium {
    font-size: $main_text_medium_size;
    font-weight: $main_text_medium_weight;
  }

  &--small {
    font-size: $title_text_small_siza;
    font-weight: $main_text_small_weight;
  }
}
</style>
