import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import Plato from '@lichi/plato';
import Logger from '@thales/logger';
import zhLocale from 'element-plus/lib/locale/lang/zh-cn';
import Track from '@thales/track';
import platoConfig from '@/utils/platoConfig';
import Thales from './thales/index';
import App from './App.vue';
import router from './router';
import store from './store';
import mixin from './minixs/index';
import Package from '../package.json';

import 'element-plus/dist/index.css';
import '@thales/theme-pro/src/index.css';
import '@lichi/plato/lib/style.css';
import './assets/scss/index.scss';

import 'nprogress/nprogress.css';

import VXETable from './plugins/vxe-table';

const sentryDsn = 'https://bec465b27c8341f3a586258cd3bb437e@sentry.yunlizhi.net/14';
const options = {
  release: Package.version,
  enable: process.env.VUE_APP_ENV === 'prod',
};
createApp(App)
  .use(Logger, sentryDsn, options)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale: zhLocale })
  .use(Plato, platoConfig)
  .use(Thales)
  .use(VXETable)
  .use(mixin)
  .use((app) => Track.init({
    domainId: 'INNER',
    appName: '仓储作业中心',
    env: process.env.VUE_APP_ENV,
  },
  app,
  router))
  .mount('#app');
