import AppLayoutLR from '@/layout/AppLayoutLR.vue';
import print from './routers/print';

// 批量导入路由
let importRoutes = [];
const ctx = require.context('./routers/modules', true, /.*\.js$/);
ctx.keys().forEach((key) => {
  importRoutes = importRoutes.concat(ctx(key).default);
});

export const menuRouters = importRoutes;

const routes = [
  {
    path: '/',
    name: '首页',
    component: AppLayoutLR,
    redirect: '/base/owner-about/owner',
    children: importRoutes,
  },
  {
    path: '/login',
    name: '登录',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
    meta: {
      icon: 'el-icon-s-order',
      hideMenu: true,
    },
  },
  {
    path: '/warehouse',
    name: '可视化仓库',
    component: () => import('../views/3d-warehouse/warehouse/index.vue'),
    meta: {
      icon: 'el-icon-s-order',
      hideMenu: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/exception/404.vue'),
  },
  print,
];

export default routes;
