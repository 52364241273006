<template>
  <div
    class="drag-item"
    draggable="true"
    @dragstart="handleDragStart"
  >
    <span v-if="title || $slots.title" class="chan-drag-item__title">
      <slot name="title">{{ title }}</slot>
    </span>
    <span v-if="suffixIcon || $slots.suffix" class="chan-drag-item__suffix">
      <slot name="suffix">
        <i :class="`iconfont chan-icon-${suffixIcon} chan-drag-item__suffix-icon`" />
      </slot>
    </span>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ChanDragItem',
  props: {
    title: String,
    suffixIcon: String,
  },
  emits: ['dragstart'],
  setup(props, { emit }) {
    // methods
    const handleDragStart = (event) => {
      emit('dragstart', event);
    };

    return {
      handleDragStart,
    };
  },
});
</script>

<style lang="scss">
.drag-item {
  width: 130px;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px dashed rgba(255, 0, 0, 0.2);
  cursor: move;
  color: #333;
  background: #fff;
  &:hover {
      background-color: rgba(255, 0, 0, 0.05)
  }
  &__title {
      flex: 1;
  }
  &__suffix {
      text-align: right;
      &-icon {
          font-size: 22px;
          color: #333;
      }
  }
}
</style>
