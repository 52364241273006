<!--
 * @Author: your name
 * @Date: 2021-10-27 15:40:28
 * @LastEditTime: 2021-10-28 15:17:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fe-wms-v2-admin\src\views\out-warehouse\wave-order\components\drawer.vue
-->
<template>
  <el-drawer
    v-bind="$attrs"
    custom-class="custom-drawer"
    @open="handleOpen"
    @close="handleClose"
  >
    <template #title>
      <title-text
        :title="title"
        size="small"
      />
    </template>
    <div class="drawer-scroll">
      <div class="body">
        <slot />
      </div>
      <slot name="footer" />
    </div>
  </el-drawer>
</template>
<script>
import TitleText from '../DrawerHeader/DrawerHeader.vue';

export default {
  name: 'Drawer',
  components: { TitleText },
  props: {
    title: {
      type: String,
      default: '',
    },
    track: {
      type: Object,
      default() { return {}; },
    },
  },
  emits: ['open', 'close'],
  data() {
    return {
      startTime: null,
    };
  },
  methods: {
    handleOpen() {
      this.startTime = Date.now();
      this.$emit('open');
    },
    handleClose() {
      if (this.track && this.track.name_zh) {
        window.YlTrack.report({
          trackType: 'view',
          duration_time: (Date.now() - this.startTime) / 1000,
          ...this.track,
        });
      }
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.custom-drawer.el-drawer.rtl {
  animation: none;
}

.custom-drawer {
  .el-drawer__header {
    margin-bottom: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebeef5;
  }

  .el-drawer__body {
    overflow: hidden;
    padding: 0;
  }

  .drawer-scroll {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    & > .body {
      flex: 1;
      width: 100%;
      padding: 30px 24px 24px;
      overflow-y: auto;
    }
  }

  .el-form--label-top .el-form-item__label {
    padding-bottom: 8px;
  }

  .el-form .el-form-item {
    margin-bottom: 22px;
    margin-right: 24px;

    .el-form-item__label {
      padding-right: 16px;
      width: 146px;
    }

    .el-checkbox {
      vertical-align: top;
      margin-right: 32px;
    }

    .el-input {
      line-height: 32px;
    }

    .el-radio {
      display: inline-flex;
      margin-right: 32px;
      height: 32px;
      line-height: normal;
    }
  }
}
</style>
