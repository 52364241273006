import KeepAliveLayout from '@/layout/KeepAliveLayout.vue';

import InWarehouse from '@/constant/pageNames/inWarehouse';

export default {
  path: '/in-warehouse',
  component: () => import('@/views/in-warehouse/index.vue'),
  meta: { title: '入库管理', icon: 'icon-ruku-moren' },
  children: [
    {
      path: 'inbound-order',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '入库单管理',
            catchName: InWarehouse.INBOUND_ORDER,
            trackNameZh: '入库管理/入库单管理/列表页',
          },
          component: () => import('@/views/in-warehouse/inbound-order/index.vue'),
        },
        {
          path: 'add',
          mata: {
            title: '新增入库单',
            trackNameZh: '入库管理/入库单管理/新增页',
          },
          component: () => import('@/views/in-warehouse/inbound-order/add.vue'),
        },
        {
          path: 'edit',
          mata: {
            title: '编辑入库单',
            trackNameZh: '入库管理/入库单管理/编辑页',
          },
          component: () => import('@/views/in-warehouse/inbound-order/add.vue'),
        },
        {
          path: 'detail',
          mata: {
            title: '入库单详情',
            trackNameZh: '入库管理/入库单管理/入库单详情',
          },
          component: () => import('@/views/in-warehouse/inbound-order/detail/index.vue'),
        },
        {
          path: 'arrivalRegistration',
          mata: {
            title: '到货登记',
            trackNameZh: '入库管理/入库单管理/抽屉-到货登记',
          },
          component: () => import('@/views/in-warehouse/inbound-order/arrivalRegistration/index.vue'),
        },
      ],
    },
    {
      path: 'acceptance',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '验收',
            catchName: InWarehouse.ACCEPTANCE,
            trackNameZh: '入库管理/验收/列表页',
          },
          component: () => import('@/views/in-warehouse/acceptance/index.vue'),
        },
        {
          path: 'confirm',
          mata: {
            title: '纸单验收',
            trackNameZh: '入库管理/验收/验收页',
          },
          component: () => import('@/views/in-warehouse/acceptance/confirm/index.vue'),
        },
        {
          path: 'detail',
          mata: {
            title: '入库单详情',
            trackNameZh: '入库管理/入库单管理/抽屉-详情',
          },
          component: () => import('@/views/in-warehouse/inbound-order/detail/index.vue'),
        },
      ],
    },
    {
      path: 'putaway',
      meta: {
        title: '纸单上架',
        trackNameZh: '入库管理/纸单上架/列表页',
      },
      component: () => import('@/views/in-warehouse/putaway/index.vue'),
    },
    {
      path: 'acceptance-detail',
      meta: {
        title: '验收明细',
        trackNameZh: '入库管理/验收明细/列表页',
      },
      component: () => import('@/views/in-warehouse/acceptance-detail/index.vue'),
    }, {
      path: 'listing-detail',
      meta: {
        title: '上架明细',
        trackNameZh: '入库管理/上架明细/列表页',
      },
      component: () => import('@/views/in-warehouse/listing-detail/index.vue'),
    }, {
      path: 'appointment-list',
      meta: {
        title: '入库预约计划-列表',
        trackNameZh: '入库管理/上架明细/入库预约计划',
      },
      component: () => import('@/views/in-warehouse/appointment-list/index.vue'),
    }],
};
