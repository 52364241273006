import printSheet from '@/views/print/config/printSheet';

export default {
  path: '/print',
  component: () => import('@/views/print/index.vue'),
  children: [
    {
      // 送货单
      path: printSheet.deliveryList.type,
      component: () => import('@/views/print/delivery-list/index.vue'),
    },
    {
      // 拣货单(总拣单)
      path: printSheet.pickingList.type,
      component: () => import('@/views/print/picking-list/index.vue'),
    },
    {
      // 分拣单
      path: printSheet.sortingList.type,
      component: () => import('@/views/print/sorting-list/index.vue'),
    },
    {
      // 验货单
      path: printSheet.storageList.type,
      component: () => import('@/views/print/storage-list/index.vue'),
    },
    {
      // 一次性容器码
      path: printSheet.disposableContainer.type,
      component: () => import('@/views/print/disposable-container/index.vue'),
    },
    {
      // 货品标签
      path: printSheet.goodsLabel.type,
      component: () => import('@/views/print/goods-label/index.vue'),
    },
    {
      // 移库单
      path: printSheet.transferList.type,
      component: () => import('@/views/print/transfer-list/index.vue'),
    },
    {
      // 入库交接单
      path: printSheet.warehouseReceiptList.type,
      component: () => import('@/views/print/warehouse-receipt-list/index.vue'),
    },
    {
      // 盘点单
      path: printSheet.countOrderList.type,
      component: () => import('@/views/print/count-order-list/index.vue'),
    },
    {
      // 容器码
      path: printSheet.locationAndContainer.type,
      component: () => import('@/views/print/location-and-container/index.vue'),
    },
    {
      // 出库单
      path: printSheet.outboundOrder.type,
      component: () => import('@/views/print/outbound-order/index.vue'),
    },
  ],
};
