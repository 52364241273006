<!--
 * @Author: your name
 * @Date: 2021-10-27 17:54:09
 * @LastEditTime: 2021-10-28 10:07:13
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \fe-wms-v2-admin\src\views\out-warehouse\wave-order\components\TitleText.vue
-->
<template>
  <span :class="`title-text-drawer title--${size}`" v-text="title" />
</template>

<script>
export default {
  props: {
    title: {
      require: true,
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';

.title-text-drawer {
  color: $title_text_color;
  height: 22px;
  font-weight: 600;
  line-height: 22px;

  &.title {
    &--medium {
      font-size: $main_text_medium_size;
      font-weight: $main_text_medium_weight;
    }

    &--small {
      font-size: $title_text_small_siza;
      font-weight: $main_text_medium_weight;
    }
  }
}

</style>
