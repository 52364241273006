import {
  LEFT, CENTER, DROP_TAG_PREFIX, GOODS_BILL,
} from '../../constants';
import { fontSizeOptions } from '../../config';

// export const positionConfig = {
//   [LEFT]: '靠左',
//   [CENTER]: '居中',
//   [RIGHT]: '靠右',
// };

// export const defaultData = {
//   text: '文字',
// };

export const defaultHeaderInfoField = {
  label: '',
  prop: '',
};

export const defaultColumn = {
  label: '',
  prop: '',
};

export default {
  name: 'goodsBill',
  drag: {
    title: '打印单',
    suffixIcon: 'input',
  },
  drop: {
    tagName: `${DROP_TAG_PREFIX}${GOODS_BILL}`,
  },
  configuration: {
    billHeader: {
      title: {
        data: {
          text: '运荔枝-送货单',
        },
        style: {
          textAlign: CENTER,
          fontSize: fontSizeOptions[5],
          height: '56px',
        },
      },
      logo: {
        style: {
          width: 'auto',
          height: '100%',
        },
      },
      barcode: {
        data: [],
        style: {},
      },
      info: {
        fields: [
          {
            label: '所属货主',
            prop: 'customerName',
          },
          {
            label: '客户订单号',
            prop: 'customerOrderNo',
          },
          {
            label: '出库单号',
            prop: 'deliverOrderNo',
          },
          {
            label: '门店名称',
            prop: 'stationName',
          },
          {
            label: '门店编码',
            prop: 'customerStationCode',
          },
          {
            label: '线路名称',
            prop: 'lineName',
          },
        ],
      },
    },
    billTable: {
      prop: 'goods',
      columns: {
        fields: [
          {
            label: '序号',
            prop: 'index',
          },
          {
            label: '货主货品编码',
            prop: 'shipperGoodsCode',
          },
          {
            label: '货品名称',
            prop: 'goodsName',
          },
          {
            label: '规格',
            prop: 'spec',
          },
          {
            label: '温层',
            prop: 'temperatureLayerTxt',
          },
          {
            label: '保质期',
            prop: 'shelfLife',
          },
        ],
      },
      style: {
        textAlign: LEFT,
        fontSize: fontSizeOptions[2],
      },
    },
    // data: { ...defaultData },
    // style: { ...defaultStyle },
  },
};
