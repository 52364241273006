<template>
  <el-table-column v-bind="{ ...$attrs, ...$props }">
    <template #default="scope">
      <slot v-bind="scope" />
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '操作',
    },
    fixed: {
      type: String,
      default: 'right',
    },
  },
};
</script>
