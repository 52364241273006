<template>
  <div class="nav-bar-layout">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="item in menuTree"
        :key="item.id"
        :to="(item.path === $route.path || item.children.length > 0) ? null : item.path"
      >
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="right-header">
      <WarehouseSelector
        v-model="account.warehouseId"
        :warehouses="warehouses"
        @change="handleWarehouseChange"
      />
      <img class="user-header" src="@/assets/user_header.png">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          {{ user.realName }}
          <i class="el-icon-caret-bottom" />
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <el-dropdown-item @click="onLogout">
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getUserWarehouses } from '@/api/base';
import WarehouseSelector from '@/components/TenantSelector/TenantSelector.vue';

export default {
  name: 'NavBar',
  components: {
    WarehouseSelector,
  },
  emits: ['warehouseChanged'],
  data() {
    return {
      menuTree: [],
      warehouses: [],
    };
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.userInfo || {},
      account: ({ user }) => user.accountInfo || {},
      menuList: ({ menu }) => menu.menu,
    }),
  },
  watch: {
    $route() {
      this.updateBreadcrumb();
    },
    menuList() {
      this.updateBreadcrumb();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initWarehouseSelect();
    },

    // ***** start ** 仓库下拉框逻辑 ** start *****

    // 初始化仓库选择框
    initWarehouseSelect() {
      this.setUserWarehouses();
    },

    // 获取租户关联的仓库列表
    async setUserWarehouses() {
      const params = {
        userId: this.account.userId || this.user.userId,
      };
      this.warehouses = await getUserWarehouses(params);
    },

    // 切换仓库逻辑：
    // 1、更新账号信息及token
    // 2、刷新页面（在布局层完成）
    async handleWarehouseChange(warehouseId) {
      await this.$store.dispatch('user/changeUnifyWarehouse', warehouseId);
      this.$emit('warehouseChanged');
    },

    // ***** end ** 仓库下拉框逻辑 ** end *****

    // 路由或者菜单变化时，更新面包屑
    updateBreadcrumb() {
      this.menuTree = [];
      let { path } = this.$route;
      this.generateBreadcrumb(this.menuList, path);
      if (this.menuTree.length === 0) {
        // 如果菜单中没有当前路径，使用父级路径
        const parentPathIndex = path.lastIndexOf('/');
        path = path.substring(0, parentPathIndex);
        this.generateBreadcrumb(this.menuList, path);
      }
    },
    // 根据路由及菜单，遍历生成面包屑
    generateBreadcrumb(menuList, path) {
      const current = menuList.find((item) => {
        if (!item.children || item.children.length === 0) {
          return item.path === path;
        }

        return this.generateBreadcrumb(item.children, path);
      });

      if (current) {
        this.menuTree.unshift(current);
      }

      return current;
    },
    async onLogout() {
      await this.$store.dispatch('user/logout');
    },
  },
};
</script>

<style lang="scss">
.nav-bar-layout {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-breadcrumb__item:last-child {
    .el-breadcrumb__inner.is-link {
      &,
      a {
        font-weight: bold;
        color: #303133;
        cursor: pointer;
      }

      &:hover,
      a:hover {
        color: #06b880;
      }
    }
  }

  .right-header {
    display: flex;
    align-items: center;
    text-align: right;

    .tenant-selector-component {
      width: 280px;
      box-shadow: 0 2px 4px 0 rgba(0, 25, 55, 0.1);
      border-radius: 6px;

      .el-input__inner {
        border: 0;
      }
    }
  }

  .user-header {
    width: 44px;
    margin: 0 16px;
  }

  .avatar-wrapper {
    cursor: pointer;
  }
}

</style>
<style lang="scss" scoped>
.user-dropdown {
  position: relative;
}
</style>
