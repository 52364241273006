const printSheet = {
  deliveryList: {
    type: 'delivery-list',
    sheetName: '送货单',
  },
  pickingList: {
    type: 'picking-list',
    sheetName: '拣货单',
  },
  sortingList: {
    type: 'sorting-list',
    sheetName: '分拣单',
  },
  storageList: {
    type: 'storage-list',
    sheetName: '验货单',
  },
  disposableContainer: {
    type: 'disposable-container',
    sheetName: '一次性容器码',
  },
  goodsLabel: {
    type: 'goods-label',
    sheetName: '货品标签',
  },
  transferList: {
    type: 'transfer-list',
    sheetName: '移库单',
  },
  warehouseReceiptList: {
    type: 'warehouse-receipt-list',
    sheetName: '移库单',
  },
  countOrderList: {
    type: 'count-order-list',
    sheetName: '盘点单',
  },
  locationAndContainer: {
    type: 'location-and-container',
    sheetName: '容器码',
  },
  outboundOrder: {
    type: 'outbound-order',
    sheetName: '出库单',
  },
};

export default printSheet;
