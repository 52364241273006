import { toRefs } from 'vue';

export default function useForm(props) {
  const { modelValue } = toRefs(props);
  const { data, style } = toRefs(modelValue.value);

  return {
    config: modelValue,
    data,
    style,
  };
}
