<template>
  <div class="list-container-component">
    <container :title="title">
      <SearchForm
        ref="searchForm"
        v-bind="$attrs"
        :fields="fields"
        @search="handleQueryFrom"
      />
    </container>
    <container ref="container" class="buttom-box">
      <slot />
    </container>
  </div>
</template>

<script>
import Container from '../Container/Container.vue';
import SearchForm from '../SearchForm/SearchForm.vue';

export default {
  components: {
    Container,
    SearchForm,
  },
  props: {
    labelWidth: {
      type: String,
      default: '100px',
    },
    title: {
      type: String,
      default: '',
    },
    fields: {
      require: true,
      type: Array,
      default: () => [],
    },
  },
  emits: ['search'],
  methods: {
    resetSearchForm() {
      this.$refs.searchForm.reset();
    },
    handleQueryFrom(data) {
      this.$emit('search', data);
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';

.list-container-component {
  height: 100%;
  // display: flex;
  flex-direction: column;
}

.buttom-box {
  // flex: 1;
  .full-content {
    height: 100%;
  }
}

.container-component {
  margin-bottom: $normal_margin_h;
}
</style>
