<template>
  <el-button
    v-bind="$attrs"
    :size="size"
    @click.prevent="handleClick"
  >
    <slot />
  </el-button>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small',
    },
    track: {
      type: Object,
      default() { return {}; },
    },
  },
  emits: ['click'],
  methods: {
    handleClick() {
      if (this.track && this.track.trackName) {
        window.YlTrack.report({
          trackType: 'click',
          ...this.track,
        });
      }
      this.$emit('click');
    },
  },
};
</script>
