import { getMenu } from '@/api/base';

const actions = {
  async update({ commit }) {
    const menu = await getMenu();
    commit('setMenu', menu);
  },
};

export default actions;
