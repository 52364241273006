import MainContent from '@/layout/MainContent.vue';

export default {
  path: '/task-center',
  component: MainContent,
  meta: { title: '任务中心', icon: 'icon-renwu' },
  children: [
    {
      path: 'task-page',
      meta: {
        title: '任务管理',
        trackNameZh: '任务中心/任务管理/列表页',
      },
      component: () => import('@/views/task-center/task-management/index.vue'),
    },
    {
      path: 'task-page/detail',
      meta: {
        title: '任务管理详情',
        trackNameZh: '任务中心/任务管理/详情',
      },
      component: () => import('@/views/task-center/task-management/detail/index.vue'),
    },
    {
      path: 'task-management',
      meta: {
        title: '任务管理',
        trackNameZh: '任务中心/任务管理/列表页',
      },
      component: () => import('@/views/task-center/task-management/index.vue'),
    },
    {
      path: 'task-management/detail',
      meta: {
        title: '任务管理详情',
        trackNameZh: '任务中心/任务管理/详情',
      },
      component: () => import('@/views/task-center/task-management/detail/index.vue'),
    },
  ],
};
