import KeepAliveLayout from '@/layout/KeepAliveLayout.vue';

import Base from '@/constant/pageNames/base';

export default {
  path: '/base',
  component: () => import('@/views/base/index.vue'),
  meta: { title: '基础数据', icon: 'icon-shuju' },
  children: [
    {
      path: 'owner-about/owner',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '货主相关',
            catchName: Base.OWNER,
            trackNameZh: '基础数据/货主相关/货主信息/列表页',
          },
          component: () => import('@/views/base/owner-about/owner/index.vue'),
        },
      ],
    },
    {
      path: 'owner-about/goods',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '货主货品信息',
            catchName: Base.GOODS,
            trackNameZh: '基础数据/货主相关/货主货品信息/列表页',
          },
          component: () => import('@/views/base/owner-about/goods/index.vue'),
        },
      ],
    },
    {
      path: 'warehouse-about/warehouse',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '仓库信息',
            catchName: Base.WAREHOUSE,
            trackNameZh: '基础数据/仓库相关/仓库信息/列表页',
          },
          component: () => import('@/views/base/warehouse-about/warehouse/index.vue'),
        },
      ],
    },
    {
      path: 'warehouse-about/warehouse-zone',
      meta: { title: '库区管理', trackNameZh: '基础数据/仓库相关/库区管理/列表页' },
      component: () => import('@/views/base/warehouse-about/warehouse-zone/index.vue'),
    },
    {
      path: 'warehouse-about/warehouse-storehouse',
      meta: { title: '库位管理', trackNameZh: '基础数据/仓库相关/库位管理/列表页' },
      component: () => import('@/views/base/warehouse-about/warehouse-storehouse/index.vue'),
    },
    {
      path: 'warehouse-about/logical-area',
      meta: { title: '逻辑区管理', trackNameZh: '基础数据/仓库相关/逻辑区管理/列表页' },
      component: () => import('@/views/base/warehouse-about/logical-area/index.vue'),
    },
    {
      path: 'warehouse-about/container',
      meta: { title: '容器管理', trackNameZh: '基础数据/仓库相关/容器管理/列表页' },
      component: () => import('@/views/base/warehouse-about/container/index.vue'),
    },
    {
      path: 'warehouse-about/loading-port',
      meta: { title: '月台管理', trackNameZh: '基础数据/仓库相关/月台管理/列表页' },
      component: () => import('@/views/base/warehouse-about/loading-port/index.vue'),
    },
    {
      path: 'warehouse-about/device',
      meta: { title: '设备管理', trackNameZh: '基础数据/仓库相关/设备管理/列表页' },
      component: () => import('@/views/base/warehouse-about/device/index.vue'),
    },
    {
      path: 'warehouse-about/pda',
      meta: { title: 'PDA管理', trackNameZh: '基础数据/仓库相关/PDA管理/列表页' },
      component: () => import('@/views/base/warehouse-about/pda/index.vue'),
    },
    {
      path: 'human-resource-management/position-management',
      meta: { title: '岗位管理', trackNameZh: '基础数据/人员管理/岗位管理/列表页' },
      component: () => import('@/views/base/human-resource-management/position-management/index.vue'),
    },
    {
      path: 'human-resource-management/staff-management',
      meta: { title: '员工管理', trackNameZh: '基础数据/人员管理/员工管理/列表页' },
      component: () => import('@/views/base/human-resource-management/staff-management/index.vue'),
    },
    {
      path: 'human-resource-management/scheduling-plan',
      component: KeepAliveLayout,
      children: [
        {
          path: '',
          meta: {
            title: '排班管理',
            catchName: Base.SCHEDULING_PLAN,
            trackNameZh: '基础数据/人员管理/排班管理/列表页',
          },
          component: () => import('@/views/base/human-resource-management/scheduling-plan/index.vue'),
        },
      ],
    },
    {
      path: 'warehouse-about/fluent-rack',
      meta: { title: '流利架管理', trackNameZh: '基础数据/仓库相关/流利架管理/列表页' },
      component: () => import('@/views/base/warehouse-about/fluent-rack/index.vue'),
    },
    {
      path: 'human-resource-management/fluent-schedule-plan',
      meta: { title: '分拣线排班', trackNameZh: '基础数据/人员管理/分拣线排班/列表页' },
      component: () => import('@/views/base/human-resource-management/fluent-schedule-plan/index.vue'),
    },
  ],
};
