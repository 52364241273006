<template>
  <div class="container-component">
    <div v-if="title" class="title">
      <title-text
        :title="title"
      />
    </div>

    <slot />
  </div>
</template>

<script>
import TitleText from '../TitleText/TitleText.vue';

export default {
  components: {
    TitleText,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';

.container-component {
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(0, 25, 55, 0.1);
  border-radius: 10px;
  padding: $normal_margin_h $normal_margin_v;

  .title {
    margin-bottom: 20px;
  }
}
</style>
