<template>
  <el-config-provider :locale="locale">
    <router-view />
    <!-- /*字体文件浏览器会懒加载，需要在引用一次后才会触发下载文件*/  -->
    <span class="font-register" />
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import InitDB from '@/utils/indexedDB/index';
import checkedColumns from '@/utils/indexedDB/table/checkedColumns';

export default {
  provide: {
    checkedTable: new InitDB(checkedColumns.name, checkedColumns.cursorIndex),
  },
  data() {
    return {
      locale: zhCn,
    };
  },
};
</script>
<style>
@font-face {
  font-family: "DinBold";
  src: url("./assets/font/DINCond-Bold.otf");
}

.font-register {
  font-size: 0;
  width: 0;
  height: 0;
  font-family: "DinBold", serif;
}
</style>
