import Encrypt from '@thales/encrypt';
import { bfsSsoHttp, bfsUserWebHttp, otmsHttp } from '@/utils/http/index';
import Auth from '@/constant/auth';

const CLIENT_CODE = 'wms_web';

/**
 * @description: 用户名+密码登录，也可以用于用户切换仓库获取token
 * @api文档：https://yapi.ops.yunlizhi.cn/project/18/interface/api/12990
 * issue: https://stackoverflow.com/questions/48524452/base64-encoder-via-crypto-js
 */
export function loginByPassword(data) {
  // 密码RSA加密
  const encrypt = new Encrypt();
  encrypt.setPublicKey(Auth.PUBLIC_KEY);// 此处为RSA公钥

  const params = {
    ...data,
    clientCode: CLIENT_CODE,
    password: encrypt.encrypt(data.password),
    encryptType: Auth.ENCRYPT_TYPE,
  };

  return bfsSsoHttp.post('/warehouse/login_by_password', params);
}

/**
 * @description: 用户名+密码登录，也可以用于用户切换仓库获取token
 * @api文档：https://yapi.ops.yunlizhi.cn/project/18/interface/api/12990
 */
export function getUserInfo(data) {
  const params = {
    ...data,
    clientCode: CLIENT_CODE,
  };

  return bfsUserWebHttp.post('/tenant_user/own', params);
}

export function logout() {
  return bfsSsoHttp.post('/vue-admin-template/user/logout');
}

/**
 * @description: 配置中心不需要登录获取配置信息
 * @api文档：https://yapi.ops.yunlizhi.cn/project/194/interface/api/18769
 */
export function configCenter(data) {
  return otmsHttp.post('/config_center/list', data);
}
