<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="catchList">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
export default {
  computed: {
    catchList() {
      const { routes } = this.$router.options;
      const catchList = [];
      this.findAllCatchableRoute(routes, catchList);
      return catchList;
    },
  },
  methods: {
    findAllCatchableRoute(routes, catchList) {
      routes.forEach((route) => {
        if (route.meta?.catchName) {
          catchList.push(route.meta.catchName);
        }
        if (route.children) {
          this.findAllCatchableRoute(route.children, catchList);
        }
      });
    },
  },
};
</script>
