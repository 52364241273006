<template>
  <div class="main-content-container">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'MainContent',
  data() {
    return {

    };
  },
  computed: {
    catchList() {
      const { routes } = this.$router.options;
      const catchList = [];
      this.findAllCatchableRoute(routes, catchList);
      return catchList;
    },
  },
  created() {},
  mounted() {
    // 获取数据字典
    this.$store.dispatch('app/updateDictionary');
  },
  methods: {
    findAllCatchableRoute(routes, catchList) {
      routes.forEach((route) => {
        if (route.meta?.catchName) {
          catchList.push(route.meta.catchName);
        }
        if (route.children) {
          this.findAllCatchableRoute(route.children, catchList);
        }
      });
    },
  },
};
</script>

<style scoped>
.main-content-container {
  overflow: auto;
}
</style>
