import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 入库单异常记录列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14328
 */
export function recordPage(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/ex/record/page', data, {
    params,
  });
}
/**
 * @description: 入库单异常记录列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14376
 */
export function getReceiptOrderCount(params) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/ex/record/count', params);
}
