<template>
  <el-dropdown trigger="click" @command="handleSwitchSystem">
    <span v-if="isOpen" class="el-dropdown-link">切换<i class="el-icon-caret-bottom" /> </span>
    <img
      v-else
      class="dropdownImg"
      src="@/assets/logo/logo-mini.png"
    >
    <template #dropdown>
      <el-dropdown-menu
        size="small"
        class="sidebar-switch-lists"
      >
        <el-dropdown-item
          v-for="(item, index) in list"
          :key="index"
          :command="item"
        >
          {{ item.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import env from '../../../env';

const store = useStore();
defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  isOpen: {
    type: Boolean,
    default: true,
  },
});
const legacyToken = computed(() => store.state.user.accountInfo?.ext?.legacyToken);
const handleSwitchSystem = (system) => {
  const loginUrl = env.LOGIN_URL || 'https://yunlizhi.cn';
  window.location = `${system.value}?token=${legacyToken.value}&systemCode=${system.systemCode}&loginUrl=${loginUrl}`;
};
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #969799;
}

.sidebar-switch-lists {
  box-sizing: border-box;
  padding: 0;
  width: 180px;
}

.dropdownImg {
  width: 24px !important;
  height: 24px !important;
  background-color: #fff !important;
  border-radius: 3px;
}
</style>
