import { toRefs } from 'vue';

export default function useForm(props) {
  const { modelValue } = toRefs(props);

  const addColumn = (column) => {
    modelValue.value.columns.push(column);
  };

  const editColumn = (column, index) => {
    modelValue.value.columns[index] = column;
  };

  const removeColumn = (index) => {
    modelValue.value.columns.splice(index, 1);
  };

  const updateColumns = (column, index = -1) => {
    const isAdd = index < 0;

    if (isAdd) {
      addColumn(column);
      return;
    }

    editColumn(column, index);
  };

  return {
    config: modelValue,
    addColumn,
    removeColumn,
    updateColumns,
  };
}
