<template>
  <div>
    <el-form
      label-width="90px"
      :model="modelValue"
    >
      <el-collapse>
        <!-- <el-collapse v-model="activeNames" @change="handleChange"> -->
        <el-collapse-item title="表头" name="1">
          <el-form-item label="标题" prop="billHeader.title.data.text">
            <el-input v-model="billHeader.title.data.text" />
          </el-form-item>
          <el-form-item label="标题字体大小" prop="billHeader.title.style.fontSize">
            <el-select v-model="billHeader.title.style.fontSize">
              <el-option
                v-for="item in fontSizeOptions"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="标题位置" prop="billHeader.title.style.textAlign">
            <el-select v-model="billHeader.title.style.textAlign">
              <el-option
                v-for="item in positionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="标题高度" prop="billHeader.title.style.height">
            <el-input v-model="billHeader.title.style.height" />
          </el-form-item>
          <el-form-item label="logo宽度" prop="billHeader.title.style.fontSize">
            <el-input v-model="billHeader.logo.style.width" />
          </el-form-item>
          <el-form-item label="logo高度" prop="billHeader.title.style.fontSize">
            <el-input v-model="billHeader.logo.style.height" />
          </el-form-item>
          <div>
            <vxe-button @click="handleAddBarcode">
              添加条码
            </vxe-button>
          </div>
          <vxe-table
            ref="barcodeTable"
            border
            resizable
            show-overflow
            :data="billHeader.barcode.data"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            @edit-closed="handleEditBarcode"
          >
            <vxe-column
              field="codeProp"
              title="关联字段"
              :edit-render="{autofocus: '.vxe-input--inner'}"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.codeProp" type="text" />
              </template>
            </vxe-column>
            <vxe-column
              title="操作"
              width="80"
              show-overflow
            >
              <template #default="{ row }">
                <el-button
                  type="text"
                  size="small"
                  @click="handleRemoveBarcode(row)"
                >
                  删除
                </el-button>
              </template>
            </vxe-column>
          </vxe-table>
          <div>
            <vxe-button @click="handleAddInfo">
              添加信息
            </vxe-button>
          </div>
          <vxe-table
            ref="infoTable"
            border
            resizable
            show-overflow
            :data="billHeader.info.fields"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            @edit-closed="handleEditInfo"
          >
            <vxe-column
              field="label"
              title="名称"
              :edit-render="{autofocus: '.vxe-input--inner'}"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.label" type="text" />
              </template>
            </vxe-column>
            <vxe-column
              field="prop"
              title="key"
              :edit-render="{autofocus: '.vxe-input--inner'}"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.prop" type="text" />
              </template>
            </vxe-column>
            <vxe-column
              title="操作"
              width="80"
              show-overflow
            >
              <template #default="{ row }">
                <el-button
                  type="text"
                  size="small"
                  @click="handleRemoveInfo(row)"
                >
                  删除
                </el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </el-collapse-item>
        <el-collapse-item title="表格" name="2">
          <el-form-item label="关联字段" prop="billTable.prop">
            <el-input v-model="billTable.prop" />
          </el-form-item>
          <el-form-item label="字体大小" prop="billTable.style.fontSize">
            <el-select v-model="billTable.style.fontSize">
              <el-option
                v-for="item in fontSizeOptions"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="标题位置" prop="billTable.style.textAlign">
            <el-select v-model="billTable.style.textAlign">
              <el-option
                v-for="item in positionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div>
            <vxe-button @click="handleAddTable">
              添加表格内容
            </vxe-button>
          </div>
          <vxe-table
            ref="dataTable"
            border
            resizable
            show-overflow
            :data="billTable.columns.fields"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            @edit-closed="handleEditTable"
          >
            <vxe-column
              field="label"
              title="表头"
              :edit-render="{autofocus: '.vxe-input--inner'}"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.label" type="text" />
              </template>
            </vxe-column>
            <vxe-column
              field="prop"
              title="key"
              :edit-render="{autofocus: '.vxe-input--inner'}"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.prop" type="text" />
              </template>
            </vxe-column>
            <vxe-column
              title="操作"
              width="80"
              show-overflow
            >
              <template #default="{ row }">
                <el-button
                  type="text"
                  size="small"
                  @click="handleRemoveTable(row)"
                >
                  删除
                </el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import {
  ElForm, ElFormItem, ElInput, ElSelect, ElOption, ElCollapse, ElCollapseItem,
} from 'element-plus';

import { positionOptions, fontSizeOptions } from '../../config';
import {
  defaultHeaderInfoField,
  defaultColumn,
} from './config';

import useEditorText from './useEditorText';
import useVxeTable from '../../uses/use-vxe-table';

export default defineComponent({
  components: {
    ElForm, ElFormItem, ElInput, ElSelect, ElOption, ElCollapse, ElCollapseItem,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  // emits: ['update:modelValue'],
  setup(props) {
    const infoTable = ref({});
    const dataTable = ref({});
    const barcodeTable = ref({});
    const {
      billHeader,
      billTable,
    } = useEditorText(props);
    const {
      handleAdd,
      handleRemove,
      handleEdit,
    } = useVxeTable();

    const handleAddInfo = () => {
      handleAdd(infoTable.value, billHeader.value.info.fields, defaultHeaderInfoField);
    };

    const handleRemoveInfo = (row) => {
      handleRemove(infoTable.value, row, billHeader.value.info.fields);
    };

    const handleEditInfo = ({ row, column }) => {
      handleEdit(infoTable.value, row, column.property);
    };

    const handleAddTable = () => {
      handleAdd(dataTable.value, billTable.value.columns.fields, defaultColumn);
    };

    const handleRemoveTable = (row) => {
      handleRemove(dataTable.value, row, billTable.value.columns.fields);
    };

    const handleEditTable = ({ row, column }) => {
      handleEdit(dataTable.value, row, column.property);
    };

    const handleAddBarcode = () => {
      handleAdd(barcodeTable.value, billHeader.value.barcode.data, defaultColumn);
    };

    const handleRemoveBarcode = (row) => {
      handleRemove(barcodeTable.value, row, billHeader.value.barcode.data);
    };

    const handleEditBarcode = ({ row, column }) => {
      handleEdit(barcodeTable.value, row, column.property);
    };

    return {
      infoTable,
      dataTable,
      barcodeTable,
      billHeader,
      billTable,
      fontSizeOptions,
      positionOptions,
      handleAddInfo,
      handleRemoveInfo,
      handleEditInfo,
      handleAddTable,
      handleRemoveTable,
      handleEditTable,
      handleAddBarcode,
      handleRemoveBarcode,
      handleEditBarcode,
    };
  },
});
</script>
