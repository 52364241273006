<template>
  <div class="attachment-upload">
    <el-upload
      :action="upLoadUrl"
      :headers="{
        Authorization: getToken(),
      }"
      :data="uploadData"
      :before-upload="handleBeforeUpload"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :on-remove="handleOnRemove"
      :on-progress="handleOnProgress"
      multiple
      :show-file-list="false"
      :file-list="uploadFileList"
      :disabled="disabled"
    >
      附件：<nh-button
        v-if="!disabled"
        type="text"
      >
        + 添加附件
      </nh-button>
    </el-upload>
    <span>
      上传时间
    </span>
    <div class="active">
      <nh-button
        type="text"
        @click="handleAllUpload(uploadFile)"
      >
        一键下载
      </nh-button>
      <nh-button
        v-if="!disabled"
        type="text"
        class="btn-delete"
        :loading="loading.handleOnRemove"
        @click="handleOnRemove"
      >
        一键删除
      </nh-button>
    </div>
  </div>

  <div v-if="progressList.length > 0" :style="uploadsStyle">
    <div
      v-for="(item, i) in progressList"
      :key="i"
    >
      <div class="attachment-upload__list">
        <div>{{ item.fileName }}</div>
        <template v-if="item.status === 'success'">
          <div>{{ item.fileTime }}</div>
          <div>
            <nh-button
              type="text"
              @click="handleOpenFile(item)"
            >
              下载
            </nh-button>
            <nh-button
              v-if="!disabled"
              type="text"
              class="btn-delete"
              @click="handleRemove(item, i)"
            >
              删除
            </nh-button>
          </div>
        </template>
      </div>
      <el-progress
        v-if="item.status !== 'success'"
        :percentage="item.percentage"
        color="#06B880"
      />
    </div>
  </div>
</template>

<script setup>
import {
  toRefs, defineProps, defineEmits,
  watch,
} from 'vue';
import { ElMessage } from 'element-plus';
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';
import { getToken } from '@/utils/auth';

import data from './data';
import downloadUrlFile from './uses/uploadFile';

const emit = defineEmits(['update:uploadFile', 'onRemove', 'onAllRemove']);
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  uploadFile: {
    type: Array,
    default: () => [],
  },
  beforeUpload: {
    type: Function,
    default: () => true,
  },
  uploadsStyle: {
    type: Object,
    default: () => {},
  },
});

const {
  upLoadUrl,
  uploadData,
  uploadFileList,
  progressList,
  loading,
} = data();

const {
  uploadFile,
  disabled,
  uploadsStyle,
} = toRefs(props);

watch(
  () => uploadFile.value,
  () => {
    uploadFileList.value = uploadFile.value;
    progressList.value = uploadFile.value.map((item) => ({
      ...item,
      status: 'success',
    }));
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  },
);
const handleBeforeUpload = (file) => {
  const fileExtension = file.name.split('.').pop();
  uploadData.value.fileExtension = fileExtension;
  return props.beforeUpload();
};

const handleUploadSuccess = (res, file, files) => {
  const fileList = files.filter((item) => {
    if (!('response' in item) || item.response.data) {
      return true;
    }
    ElMessage({
      message: item.response.msg,
      type: 'error',
      duration: 5 * 1000,
    });
    return false;
  }).map((item) => {
    const list = {
      ...item,
      fileName: item.name || item.fileName,
      filePath: item.url || item.filePath,
      fileTime: item.fileTime || moment().format(YMDHMS),
    };
    if (!item.url) {
      const [info = {}] = item.response?.data || [];
      list.filePath = info.url;
    }
    return list;
  });
  emit('update:uploadFile', fileList);
  uploadFileList.value = fileList;
};
const handleUploadError = (err, file, files) => {
  if (err.message) {
    ElMessage({
      message: JSON.parse(err.message)?.msg,
      type: 'error',
      duration: 5 * 1000,
    });
  }
  emit('update:uploadFile', files);
  uploadFileList.value = files;
};
function handleOnProgress(event, file, files) {
  const fileList = files.map((item) => ({
    ...item,
    fileName: item.fileName || item.name,
    percentage: Math.floor(item.percentage),
  }));
  progressList.value = fileList;
}
const handleRemove = (row, index) => {
  emit('onRemove', { row, index });
};
const handleOnRemove = () => {
  emit('onAllRemove');
};
const handleOpenFile = (file) => {
  downloadUrlFile(file.filePath, file.fileName);
};
const handleAllUpload = () => {
  this.fileData.forEach((item) => {
    downloadUrlFile(item.filePath, item.fileName);
  });
};
</script>
<style lang="scss" scoped>
.attachment-upload,
.attachment-upload__list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    &:first-of-type {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-of-type {
      display: flex;

      .el-button {
        width: 70px;
        text-align: left;
      }

      .btn-delete {
        color: #f56c6c;
      }
    }
  }
}

</style>
