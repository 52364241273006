<template>
  <div
    :class="[
      'search-form-component',
      moreButton.text === MoreButtonStatus.CLOSE.text ? 'no-more' : ''
    ]"
  >
    <el-form
      ref="searchForm"
      :label-width="labelWidth"
      :inline="true"
      :model="searchQuery"
      class="left-container"
      :size="size"
    >
      <el-form-item
        v-for="field in fields"
        :key="field.label"
        v-bind="field"
      >
        <component
          :is="field.componentType"
          v-model="searchQuery[field.prop]"
          v-bind="field"
        >
          <template v-if="field.componentType === FormType.SELECT" #default>
            <el-option
              v-for="option in field.options"
              :key="option.value || option.label"
              :label="option.label"
              :value="option.value"
            />
          </template>
        </component>
      </el-form-item>
    </el-form>
    <div class="right-container button-label">
      <span
        v-show="showMoreButton"
        class="more-button"
        @click="onMoreClick"
      >{{ moreButton.text }} <i class="iconfont" :class="moreButton.icon" /></span>
      <nh-button
        type="primary"
        :size="size"
        @click="onSerch"
      >
        查询
      </nh-button>
      <nh-button :size="size" @click="onReset">
        重置
      </nh-button>
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import { FormType } from '@/constant/form';

const MoreButtonStatus = {
  OPEN: {
    text: '折叠筛选',
    icon: 'iconcaret-top',
  },
  CLOSE: {
    text: '展开筛选',
    icon: 'iconcaret-bottom',
  },
};

// 不同尺寸所对应的页面宽度和每行 ElFormItem 个数
const DifferentSizeData = [
  { width: 1200, perLineCount: 4 }, // large
  { width: 992, perLineCount: 3 }, // middle
  { width: 768, perLineCount: 2 }, // small
  { width: 0, perLineCount: 1 }, // less than small
];

export default {
  props: {
    labelWidth: {
      type: String,
      default: '100px',
    },
    size: {
      type: String,
      default: 'small',
    },
    fields: {
      require: true,
      type: Array,
      default: () => [],
    },
    // 默认折叠时，搜索框显示的搜索条件的行数
    defaultLineNumber: {
      type: Number,
      default: 2,
    },
    // 在初始化页面时，是否展示全部搜索条件
    showAllFields: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['search', 'tiggleMore'],
  data() {
    return {
      MoreButtonStatus,
      // 是否显示折叠状态
      displayAllFields: false,
      FormType,
      searchQuery: {},
    };
  },
  computed: {
    perLineItemCount() {
      const documentScrollWidth = document.documentElement.scrollWidth;
      const screen = DifferentSizeData.find((item) => documentScrollWidth >= item.width);
      return screen.perLineCount;
    },
    // 是否展示折叠按钮
    showMoreButton() {
      const displayCount = this.perLineItemCount * this.defaultLineNumber;

      return this.fields.length > displayCount;
    },
    moreButton() {
      return this.displayAllFields ? MoreButtonStatus.OPEN : MoreButtonStatus.CLOSE;
    },
  },
  created() {
    // 初始化表单查询的参数
    this.initSearchQuery();
  },
  mounted() {
    // 初始化折叠按钮
    this.initMoreButton();
  },
  methods: {
    // 初始化表单查询的参数
    initSearchQuery() {
      const searchQuery = {};
      this.fields.forEach((field) => {
        searchQuery[field.prop] = field.default || null;
      });
      this.searchQuery = { ...searchQuery };
    },
    // 初始化折叠按钮
    initMoreButton() {
      this.initMoreButtonStatus();
    },
    // 页面初始化时，是否展开搜索条件
    initMoreButtonStatus() {
      this.displayAllFields = this.showAllFields;
    },
    onReset() {
      this.reset();
      this.onSerch();
    },
    reset() {
      this.$refs.searchForm.resetFields();
    },
    onSerch() {
      this.$emit('search', this.searchQuery);
    },
    onMoreClick() {
      this.displayAllFields = !this.displayAllFields;
      this.$emit('tiggleMore');
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';

.search-form-component {
  display: flex;
  overflow: hidden;

  .button-label {
    min-width: 300px;
    text-align: right;
  }

  &.no-more {
    max-height: 116px;
  }

  .left-container {
    flex: 1;
    margin-bottom: -20px;
  }

  .el-button--medium {
    padding: 8px 20px;
  }

  .el-input,
  .el-input__inner {
    &.el-date-editor-- {
      &timerange,
      &daterange,
      &datetimerange {
        width: 510px;
      }
    }
  }

  .el-input--medium .el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  .more-button {
    margin-right: $normal_margin_h;

    &:hover {
      cursor: pointer;
    }
  }
  // 重写elemnt-ui样式
  .el-input,
  .el-select {
    width: 200px;
  }

  // / 重写elemnt-ui样式
}
</style>
