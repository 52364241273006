import Http from '@thales/http/src/index';
import { ElMessage } from 'element-plus';
import { getToken, getLongToken } from '../auth';
import sessionExpiredHandler from './sessionExpiredHandler';

let msgConut = 0; // session报错次数
export default (prefix, options = {}) => Http.create({
  prefix,
  $message: (error) => {
    // session 报错次数小于1
    if (msgConut <= 1) {
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      });
    }
  },
  // session 默认过期规则： 为 -1000 或者 符合/^400\d{3}08\d{2}$/ 即代表 session 过期
  sessionExpiredHandler() {
    msgConut += 1;
    sessionExpiredHandler();
  },
  // 请求成功自定义事件
  loggerHandler() {
    msgConut = 0;
  },
  tokenHandler(config) {
    let { headers, token } = config;
    if (!token) {
      token = getToken();
      headers.Authorization = token;
    }
    // 如果存在长token 则写入长token
    const longToken = getLongToken();
    if (longToken) {
      headers.Token = longToken;
    }
    headers = headers || {};
    return { ...config, headers };
  },
  ...options,
});
