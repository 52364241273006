import configDB from './config';

class InitDB extends configDB {
  tableName;

  constructor(tableName) {
    super();
    this.tableName = tableName;
  }

  async get(key) {
    let data;
    try {
      data = await super.get(key, this.tableName);
    } catch {
      data = null;
    }
    return data;
  }

  set(value, key) {
    super.put(value, key, this.tableName);
  }

  delete(key) {
    super.delete(key, this.tableName);
  }

  clear() {
    super.clear(this.tableName);
  }
}

export default InitDB;
