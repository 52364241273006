import { otmsHttp, wmsPlusHttp } from '@/utils/http/index';
/**
 * @description: 数据字典接口
 * @api: https://yapi.ops.yunlizhi.cn/project/388/interface/api/11212
 */
export function getDictionaryFromWms() {
  return wmsPlusHttp.get('/warehouse_foundation/dictionary/get_dictionary_json');
}

/**
 * @description: 获取用户菜单
 * @api: https://yapi.ops.yunlizhi.cn/project/337/interface/api/11040
 */
export function getMenu() {
  const params = {
    systemCode: 'wms2.0',
  };

  return otmsHttp.post('/permission-plus/function/menu/tree', params);
}

/**
 * @description: 查询租户列表
 * @api: https://yapi.ops.yunlizhi.cn/project/10/interface/api/689
 */
export function getTenants(params) {
  return otmsHttp.post('/bfs_user/tenant_user/authorized_tenant/list', params);
}

/**
 * @description: 查询用户关联的仓库
 * @api: https://yapi.ops.yunlizhi.cn/project/388/interface/api/13044
 */
export function getUserWarehouses(data) {
  const params = {
    ...data,
    warehouseId: '',
  };
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_user/bind/list', params);
}
