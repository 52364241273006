<!--
 * @Author: your name
 * @Date: 2021-09-17 15:42:54
 * @LastEditTime: 2021-09-17 16:28:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fe-wms-v2-admin\src\thales\Link\Link.vue
-->
<template>
  <router-link
    class="link-component"
    tag="a"
    v-bind="$attrs"
  >
    <slot />
  </router-link>
</template>
<style lang="scss">
.link-component {
  color: #06b880;
  text-decoration: none;

  & + .link-component,
  & + .el-button {
    margin-left: 16px;
  }

  &:hover {
    color: #2ad09c;
  }
}

.el-button + .link-component {
  margin-left: 16px;
}

</style>
