const LEFT = 'left';
const CENTER = 'center';
const RIGHT = 'right';

export const positionConfig = {
  [LEFT]: '靠左',
  [CENTER]: '居中',
  [RIGHT]: '靠右',
};

export const positionOptions = Object.keys(positionConfig).map((key) => ({
  value: key,
  label: positionConfig[key],
}));

export const fontSizeOptions = ['12px', '13px', '14px', '15px', '16px', '20px'];

export const defaultData = {
  text: '文字',
};

export const defaultStyle = {
  textAlign: LEFT,
  height: 'auto',
  width: '100%',
  fontSize: fontSizeOptions[2],
};

export default {
  name: 'text',
  drag: {
    title: '文本框',
    suffixIcon: 'input',
  },
  drop: {
    tagName: 'chan-drop-text',
  },
  editor: {
    text: '文字',
    align: '位置',
  },
  configuration: {
    data: { ...defaultData },
    style: { ...defaultStyle },
  },
};
