import { toRefs } from 'vue';

export default function useForm(props) {
  const { modelValue } = toRefs(props);
  const { billHeader, billTable } = toRefs(modelValue.value);

  return {
    billHeader,
    billTable,
  };
}
