<template>
  <el-dialog
    title="新增"
    :model-value="visible"
    @update:modelValue="handleVisibleChange"
    @close="handleClose"
  >
    <el-form
      ref="columnForm"
      label-width="100px"
      :model="columnInfo"
    >
      <el-form-item label="名称" prop="label">
        <el-input v-model="columnInfo.label" />
      </el-form-item>
      <el-form-item label="key值" prop="prop">
        <el-input v-model="columnInfo.prop" />
      </el-form-item>
      <el-form-item label="宽度" prop="width">
        <el-input v-model="columnInfo.width" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="handleSave">
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent, ref, toRefs, watch,
} from 'vue';

import {
  ElDialog, ElForm, ElFormItem, ElInput, ElButton,
} from 'element-plus';
// resetFields
export default defineComponent({
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    style: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({
        label: '',
        prop: '',
        width: '',
      }),
    },
  },
  emits: ['update:modelValue', 'update:columns', 'save'],
  setup(props, { emit }) {
    const columnForm = ref(null);
    const columnInfo = ref({
      label: '',
      prop: '',
      width: '',
    });
    const { modelValue: visible } = toRefs(props);

    const initColumnInfo = () => {
      if (props.column) {
        columnInfo.value = props.column;
      }
    };

    const handleVisibleChange = (value) => {
      emit('update:modelValue', value);
    };

    const handleSave = () => {
      emit('save', { ...columnInfo.value });
    };

    const handleClose = () => {
      columnForm.value.resetFields();
    };

    watch(visible, () => {
      if (visible) {
        initColumnInfo();
      }
    });

    return {
      visible,
      columnInfo,
      columnForm,
      handleClose,
      handleSave,
      handleVisibleChange,
    };
  },
});
</script>
