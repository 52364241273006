<template>
  <div>
    <el-form
      label-width="100px"
      :model="config"
    >
      <el-form-item label="文字">
        <el-input v-model="config.data.text" @input="handleTextChange" />
      </el-form-item>
      <el-form-item label="字体大小">
        <el-select
          v-model="config.style.fontSize"
          placeholder="Select"
          @change="handleFontSizeChange"
        >
          <el-option
            v-for="size in fontSizeOptions"
            :key="size"
            :label="size"
            :value="size"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="文字位置">
        <el-select v-model="config.style.textAlign" placeholder="Select">
          <el-option
            v-for="item in positionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="宽">
        <el-input v-model="config.style.width" @input="handleWidthChange" />
      </el-form-item>
      <el-form-item label="高">
        <el-input v-model="config.style.height" @input="handleHeightChange" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  ElForm, ElFormItem, ElInput, ElSelect, ElOption,
} from 'element-plus';
import { positionOptions, fontSizeOptions } from './config';

import useEditorText from './useEditorText';

export default defineComponent({
  components: {
    ElForm, ElFormItem, ElInput, ElSelect, ElOption,
  },
  props: {
    modelValue: {
      type: Object,
      defualt: () => ({
        data: {},
        style: {},
      }),
    },
    data: {
      type: Object,
      defualt: () => ({}),
    },
    style: {
      type: Object,
      defualt: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { config, data, style } = useEditorText(props);

    const updateConfig = (result) => {
      emit('update:modelValue', result);
    };
    const updateData = (result) => {
      updateConfig({ ...config.value, result });
    };
    const updateStyle = (result) => {
      updateConfig({ ...config.value, result });
    };

    const handleTextChange = (text) => {
      updateData({ ...data.value, text });
    };

    const handleFontSizeChange = (fontSize) => {
      updateStyle({ ...style.value, fontSize });
    };

    const handleWidthChange = (width) => {
      updateStyle({ ...style.value, width });
    };

    const handleHeightChange = (height) => {
      updateStyle({ ...style.value, height });
    };

    return {
      config,
      fontSizeOptions,
      positionOptions,
      handleTextChange,
      handleFontSizeChange,
      handleWidthChange,
      handleHeightChange,
    };
  },
});
</script>
