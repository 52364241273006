const requireMinix = require.context(
  // 其组件目录的相对路径
  './',
  // 是否查询其子目录
  false,
  // 匹配基础组件文件名的正则表达式
  /.minix.js$/,
);

export default (app) => {
  requireMinix.keys().forEach((fileName) => {
    // 获取组件配置
    const minix = requireMinix(fileName);
    app.mixin(minix.default);
  });
};
