import { USER, ACCOUNT, LOGIN_PARAMS } from '@/constant/storage';
import { localStorageGet } from '@/utils/localStorage';

const state = () => ({
  userInfo: localStorageGet(USER) || null, // 用户信息
  accountInfo: localStorageGet(ACCOUNT) || null, // 账号信息，包括token、当前仓库、用户名、密码等
  info: localStorageGet(USER) || {},
  loginParams: localStorageGet(LOGIN_PARAMS) || {},
});

export default state;
